import React, { useState } from "react";
import "./allReview.css";
import Modal from "react-responsive-modal";

export const Paragraph = ({ data, button, type }) => {
  console.log("-data-------", data?.questions);
  const [showMarkSchemesModal, setShowMarkSchemesModal] = useState(false);

  const handleMarkSchemes = (question) => {
    setShowMarkSchemesModal(true);
  };

  const handleCloseMarkSchemesModal = () => {
    setShowMarkSchemesModal(false);
  };

  return (
    <div>
      <Modal
        open={showMarkSchemesModal}
        onClose={handleCloseMarkSchemesModal}
        center
        // styles={{
        //   modal: { width: "95%", height: "80%", borderRadius: "10px" },
        // }}
        styles={{
          modal: {
            width: "95%",
            height: "80%",
            borderRadius: "10px",
            backgroundColor: "#E3F2FD",
          },
        }}
      >
        {/* Put Your soluction Code Here */}
        <div
          style={{
            flex: 1,
            padding: "30px",
            overflowY: "auto",
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: 10,
            marginTop: 30,
          }}
        >
          <h3>Paragraph {/* {currentQuestionIndex + 1}  */}:</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.paragraph,
            }}
          ></div>
        </div>
        <div
          style={{
            flex: 1,
            padding: "30px",
            overflowY: "auto",
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: 10,
            marginTop: 30,
          }}
        >
          {data?.questions?.map((i, index) => (
            <div className="mt-4">
              <p className="text-black font-bold">Question {index + 1} : </p>
              <div
                className="question-content"
                dangerouslySetInnerHTML={{
                  __html: i?.question,
                }}
              ></div>
            </div>
          ))}
        </div>
        <div
          style={{
            flex: 1,
            padding: "30px",
            overflowY: "auto",
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: 10,
            marginTop: 30,
          }}
        >
          {/* {data?.questType == "MCQ" ? ( */}
          <>
            {data?.options?.map((i, index) => (
              <div className=" mt-4">
                <p className="text-black font-bold">hgjhgjhg {index + 1} : </p>
                {/* {i?.isTrue === true && ( */}
                <div
                  className="question-content "
                  dangerouslySetInnerHTML={{
                    __html: "i?.text",
                  }}
                ></div>
                {/* )} */}
              </div>
            ))}
            {data?.mark}
          </>
          {/* ) : ( */}
          <>
            {data?.questions?.map((i, index) => (
              <>
                <div className=" mt-4">
                  <p className="text-black font-bold">Answer {index + 1} : </p>
                  <div
                    className="question-content "
                    dangerouslySetInnerHTML={{
                      __html: i?.answer,
                    }}
                  ></div>
                </div>
                {i?.options &&
                  i?.options?.map((i) => (
                    <div className=" mt-4">
                      {i?.isTrue === true && (
                        <div
                          className="question-content "
                          dangerouslySetInnerHTML={{
                            __html: i?.text,
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
              </>
            ))}
          </>
          {/* )} */}
        </div>
      </Modal>
      <div className="flex justify-between">
        <div style={{ width: "75%" }} className="  gap-5">
          <div className="flex-1">
            <div className="question-box w-full">
              <div
                className="question-content"
                dangerouslySetInnerHTML={{
                  __html: data?.paragraph,
                }}
              ></div>
            </div>
            <div className="mt-8">
              {data?.questions?.map((item, index) => (
                <>
                  {item?.questType ? (
                    <div className="flex mt-16 justify-between">
                      <div>
                        <div className="flex ">
                          <p>Q {index + 1}</p>
                          <p className="ml-4">
                            <div
                              className="question-content"
                              dangerouslySetInnerHTML={{
                                __html: item?.question,
                              }}
                            ></div>
                          </p>
                        </div>
                        <>
                          {item?.options?.map((i) => (
                            <div className="mt-4 border border-solid border-gray-600 rounded-lg p-2">
                              <div
                                className="question-content"
                                dangerouslySetInnerHTML={{
                                  __html: i?.text,
                                }}
                              ></div>
                            </div>
                          ))}
                        </>
                      </div>
                      <div
                        style={{
                          height: "100%",
                          borderRadius: "5px 0px 0px 5px",
                          marginBottom: "5px",
                          color: "#000",
                        }}
                      >
                        Marks : {item.mark}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center mt-16 justify-between">
                      <div style={{ width: "90%" }} className="flex">
                        <p>Q {index + 1}</p>
                        <p className="ml-4">
                          <div
                            className="question-content"
                            dangerouslySetInnerHTML={{
                              __html: item?.question,
                            }}
                          ></div>
                        </p>
                      </div>
                      <div
                        style={{
                          height: "100%",
                          borderRadius: "5px 0px 0px 5px",
                          marginBottom: "5px",
                          color: "#000",
                        }}
                      >
                        Marks : {item.mark}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>{" "}
          </div>
          <div>
            <div className="options-style">
              {data?.options?.map((item) => {
                return (
                  <div className="optionAll">
                    <div
                      className="question-content"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="w-0.5 bg-gray-200"></div>
        <div className="w-64">
          {/* <div style={{ color: "black" }}>
            Minimum Marks:
            {data.marks}
          </div> */}
          {/* <div style={{ marginTop: 10 }}>
            Negative Marks:
          </div> */}
          <div className="flex" style={{ marginTop: 10 }}>
            Question Type: &nbsp;
            <div
              style={{
                color:
                  data?.level === "Easy"
                    ? "#3771C8"
                    : data?.level === "Medium"
                    ? "#1FAF38"
                    : "#F71D2C",
              }}
            >
              {data.level}
            </div>
          </div>
          <div
            style={{
              backgroundColor:
                data.level === "Easy"
                  ? "rgba(0, 0, 255, 0.3)"
                  : data.level === "Medium"
                  ? "rgba(0, 255, 0, 0.5)"
                  : data.level === "Hard"
                  ? "rgba(243, 76, 48, 0.96)"
                  : "transparent",
              width:
                data.level === "Easy"
                  ? "30%"
                  : data.level === "Medium"
                  ? "45%"
                  : data.level === "Hard"
                  ? "90%"
                  : "0%",
              borderWidth: 1,
              borderColor:
                data.level === "Easy"
                  ? "rgba(0, 0, 255, 0.3)"
                  : data.level === "Medium"
                  ? "rgba(0, 255, 0, 0.5)"
                  : data.level === "Hard"
                  ? "rgba(243, 76, 48, 0.96)"
                  : "transparent",
              borderStyle: "solid",
              borderRadius: "9px",
              marginBottom: "10px",
              padding: "12px",
              marginTop: "10px",
            }}
          />

          <div className="flex flex-col">
            <button
              onClick={() => handleMarkSchemes(data)}
              style={{
                padding: "8px 5px",
                fontSize: "14px",
                borderRadius: "10px",
                fontWeight: "400",
                marginTop: "10px",
                color: "black",
                backgroundColor: "#BAB5EA",
              }}
            >
              <span role="img" aria-label="PDF icon" className="mr-1">
                📄
              </span>
              Mark Scheme
            </button>
            {button}
          </div>
        </div>
      </div>

      {/* Put your Code here */}
    </div>
  );
};
