import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/PrivacyPolicy.css";
function PrivacyPolicy() {
  return (
    <>
      <div className="main_div">
        <h1 className="privacy_heading">Privacy Policy</h1>
        <p className="paragraph">
          Last updated on JAN 18th 2024
          <br />
          <br />
          <br />
          This privacy policy sets out how STUTEACH INTELLIEDTECH PRIVATE
          LIMITED uses and protects any information that you give STUTEACH
          INTELLIEDTECH PRIVATE LIMITED when you use this website. STUTEACH
          INTELLIEDTECH PRIVATE LIMITED is committed to ensuring that your
          privacy is protected. Should we ask you to provide certain information
          by which you can be identified when using this website, and then you
          can be assured that it will only be used in accordance with this
          privacy statement. STUTEACH INTELLIEDTECH PRIVATE LIMITED may change
          this policy from time to time by updating this page. You should check
          this page from time to time to ensure that you are happy with any
          changes.
        </p>
        <p className="paragraph">
          Last updated on JAN 18th 2024
          <br />
          <br />
          <br />
          STUTEACH INTELLIEDTECH PRIVATE LIMITED is committed to ensuring that
          your privacy is protected. Should we ask you to provide certain
          information by which you can be identified when using this website,
          and then you can be assured that it will only be used in accordance
          with this privacy statement. our- Apps:
          <li style={{listStyle:"none",fontSize:30,marginTop:"10px",textDecoration: "underline"}}>
            <a target="_blank" href="https://drive.google.com/file/d/1VznuxlEA1WkBgROO4R4fCdcnTCv13lML/view">Student App</a>
          </li>
          <li style={{listStyle:"none",fontSize:30,marginTop:"10px",textDecoration: "underline"}}>
            <a target="_blank" href="https://drive.google.com/file/d/1b72Y-28t7CJCr5EGag4yqY5jFQ4mwD68/view">Teacher App</a>
          </li>
        </p>
        <h2 className="privacy_heading2">Information We Collect</h2>
        <p className="paragraph">
          We may collect the following information:
          <br />
          1. Name and job title;
          <br />
          2. Contact information including email address;
          <br />
          3. Demographic information such as postcode, preferences and
          interests;
          <br />
          4. Other information relevant to customer surveys and/or offers.
          <br />
        </p>
        <h2 className="privacy_heading2">How we use cookies:</h2>
        <p className="paragraph">
          A cookie is a small file which asks permission to be placed on your
          computer's hard drive. Once you agree, the file is added and the
          cookie helps analyses web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences. We use traffic log cookies to identify which
          pages are being used. This helps us analyses data about webpage
          traffic and improve our website in order to tailor it to customer
          needs. We only use this information for statistical analysis purposes
          and then the data is removed from the system. Overall, cookies help us
          provide you with a better website, by enabling us to monitor which
          pages you find useful and which you do not. A cookie in no way gives
          us access to your computer or any information about you, other than
          the data you choose to share with us. You can choose to accept or
          decline cookies. Most web browsers automatically accept cookies, but
          you can usually modify your browser setting to decline cookies if you
          prefer. This may prevent you from taking full advantage of the
          website.
        </p>
        <h2 className="privacy_heading2">How we Use Your Information</h2>
        <p className="paragraph">
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
          <br />
          1.Internal record keeping;
          <br />
          2.We may use the information to improve our products and services;
          <br />
          3. We may periodically send promotional emails about new products,
          special offers or other information which we think you may find
          interesting using the email address which you have provided. ;
          <br />
          4. From time to time, we may also use your information to contact you
          for market research purposes. We may contact you by email, phone, fax
          or mail. We may use the information to customise the website according
          to your interests.
          <br />
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure we have put in suitable
          measures.
          <br />
        </p>
        <h2 className="privacy_heading2">
          Controlling your personal information
        </h2>
        <p className="paragraph">
          You may choose to restrict the collection or use of your personal
          information in the following ways:
          <br />
          1. whenever you are asked to fill in a form on the website, look for
          the box that you can click to indicate that you do not want the
          information to be used by anybody for direct marketing purposes
          <br />
          2. if you have previously agreed to us using your personal information
          for direct marketing purposes, you may change your mind at any time by
          writing to or emailing us at support@intelliedtech.com
        </p>
        <h2 className="privacy_heading2">
          Changes and Updates to this Privacy Policy
        </h2>
        <p className="paragraph">
          From time to time, we may revise the Policy. To help you stay current
          of any changes, we note the date the Privacy Policy was last updated
          above.
        </p>
        <h2 className="privacy_heading2">Contact Information</h2>
        <p className="paragraph">
          Please contact with any questions or comments about this Policy, your
          information, our third-party disclosure practices, or your consent
          choices.
          <br />
          Email: support@intelliedtech.com
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
