import React, { useEffect, useState } from 'react'
import "../styles/serviceComp.css"

import Select from "react-select";
import { gradeData, languageData, subjectsData } from '../utils/data';

import loader from "../assets/loader/loader.gif";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import featuresCards from '../utils/cards';

function HomeTutoringComp() {
  const [name, setName] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  // const [userClass, setUserClass] = useState(null);
  const [targetExam, setTargetExam] = useState(null);
  const [priceRange, setPriceRange] = useState(null);
  const [pinCode,setPinCode]=useState(null)
  const [loading,setLoading]=useState(false)
  const [classes,setClasses]=useState(null)
  // const [address,setAddress]=useState(null)
  const navigate=useNavigate()

  const getWidthOfDropdown = () => {
    if(window.innerWidth > 600){
      return  "242px";
    }else{
return  "80vw";
    } 
  };

  
  useEffect(() => {

    window.addEventListener('resize', getWidthOfDropdown);

    return () => {
      window.removeEventListener('resize', getWidthOfDropdown);
    };
  }, []);

 
  const BaseUrl = 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/user-mgmt';

  const handleStartNow = async () => {
    // Validation checks for each field
    setLoading(true); // Set loading to true when starting the API call

    if (!name || !mobileNumber    || !classes || !targetExam  || !pinCode ) {
      toast.error('All fields are mandatory. Please fill in all the details.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Auto close the toast after 5 seconds
      });
    setLoading(false); // Set loading to true when starting the API call

      return; // Stop execution if any field is empty
    }
  

    try {
      // Assuming you have an API endpoint to post the tutoring information
      const response = await axios.post(`${BaseUrl}/ums/apis/v1/users/home-tuition`, {
        name,
        phoneNumber: mobileNumber,
        subject:selectedSubject,
        // language: selectedLanguage,  
        grade:classes,
        targetExam: targetExam,
        priceLimit: parseFloat(priceRange), // Assuming priceLimit is a number
        pinCode,
        // address,
        email,
      });

      // Handle the response as needed
      console.log('API Response:', response.data);
      setLoading(false)
      
      // Show success toast on successful submission
      toast.success('Your request is submitted successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Auto close the toast after 3 seconds
      });

      // You can also navigate or perform other actions after successful submission
      navigate("/")
    } catch (error) {
      setLoading(false)

      // Handle error, e.g., show an error message to the user
      toast.error('Error submitting your request. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Auto close the toast after 5 seconds
      });
      console.error('Error:', error);
    }
  };
  return (
    <div id="HomeTutoring"  className='service_comp_mainContainer'>
      <h1 style={{color : featuresCards[2].color}}>Home Tutoring</h1>

      <div className='service_comp_mainContainer_inp_div'>
<label>Name *</label>

      <input
            className="service_comp_mainContainer_inp"
            value={name}
            type='text'
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Your Name"
          />
          </div>
          
          <div className='service_comp_mainContainer_inp_div'>
<label>Mobile Number *</label>
      <input
            className="service_comp_mainContainer_inp"
            value={mobileNumber}
            type='number'
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter Your Mobile Number"
          />
          </div>

          <div className='service_comp_mainContainer_inp_div'>
<label>Subject</label>


    
           <Select
            className="service_comp_mainContainer_dropdown"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                    width: "max-content",
                   minWidth: "100%",
                   borderRadius:"10px",
                   border:"1px solid #032539 ",
                borderColor: "white",
                fontSize:"15px",
                fontFamily:"Poppins",
                height: "48px",
                cursor: "pointer",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#6B4EFF",
              }),
            }}
            placeholder="Select Subject"
            options={subjectsData}
            onChange={(selectedOption) =>
              setSelectedSubject(selectedOption.value)
            }
          />

          </div>

          <div className='service_comp_mainContainer_inp_div'>
<label>Email</label>

            <input
            className="service_comp_mainContainer_inp"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Your Email"
          />
        </div>

        {/* <div className='service_comp_mainContainer_inp_div'>
<label>Language</label>

        <Select
            className="service_comp_mainContainer_dropdown"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                    width: "max-content",
                   minWidth: "100%",
                borderColor: "white",
                borderRadius:"10px",
                border:"1px solid #032539 ",
                height: "20px",
                fontSize:"15px",
                fontFamily:"Poppins",
                cursor: "pointer",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#6B4EFF",
              }),
            }}
            placeholder="Select Language"
            options={languageData}
            onChange={(selectedOption) =>
              setSelectedLanguage(selectedOption.value)
            }
          />

          </div> */}

               {/* <input
            className="service_comp_mainContainer_inp"
            value={userClass}
            type='text'
            onChange={(e) => setUserClass(e.target.value)}
            placeholder="Enter Your Class "
          /> */}

<div className='service_comp_mainContainer_inp_div'>
<label>Target Exam *</label>

             <input
            className="service_comp_mainContainer_inp"
            value={targetExam}
            onChange={(e) => setTargetExam(e.target.value)}
            placeholder="Enter Your Target Exam "
          />

          </div>

          <div className='service_comp_mainContainer_inp_div'>
<label>Price Range</label>

             <input
            className="service_comp_mainContainer_inp"
            value={priceRange}
            type='text'
            onChange={(e) => setPriceRange(e.target.value)}
            placeholder="Enter Your Price Range "
          />
        </div>

        <div className='service_comp_mainContainer_inp_div'>
<label>Pincode *</label>

             <input
            className="service_comp_mainContainer_inp"
            value={pinCode}
            type='number'
            onChange={(e) => setPinCode(e.target.value)}
            placeholder="Enter Your Pincode "
          />
        </div>
        
        <div className='service_comp_mainContainer_inp_div'>
<label>Class *</label>
             <input
            className="service_comp_mainContainer_inp"
            value={classes}
            type='text'
            onChange={(e) => setClasses(e.target.value)}
            placeholder="Enter Your Class "
          />
          </div>

{/*         
             <input
            className="service_comp_mainContainer_inp"
            value={address}
            type='text'
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter Your Address "
          />
         */}


        {loading===true ?
                <img className='service_comp_mainContainer_loader' src={loader} />
      :
          <button 
          style={{backgroundColor : featuresCards[2].color}}
            className="service_comp_mainContainer_btn"
            onClick={handleStartNow}
          >
          Connect Now
          </button>
        }

    </div>
  )
}

export default HomeTutoringComp
