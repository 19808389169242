import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/Tnc.css";
function CnR() {
  return (
    <>
      <div className="main_div">
        <h1 className="privacy_heading">Cancellation & Refund Policy</h1>
        <p className="paragraph">
          Last updated on Nov 20th 2023
          <br />
          <br />
          <br />
        </p>
        <h2 className="privacy_heading2">
          STUTEACH INTELLIEDTECH PRIVATE LIMITED believes in helping its
          customers as far as possible, and has therefore a liberal cancellation
          policy. Under this policy:
        </h2>
        <p className="paragraph">
          <br />
          1. Cancellations will be considered only if the request is made
          immediately after placing the order. However, the cancellation request
          may not be entertained if the orders have been communicated to the
          vendors/merchants and they have initiated the process of shipping
          them.
          <br />
          2.STUTEACH INTELLIEDTECH PRIVATE LIMITED does not accept cancellation
          requests for perishable items like flowers, eatables etc. However,
          refund/replacement can be made if the customer establishes that the
          quality of product delivered is not good.
          <br />
          3. In case of receipt of damaged or defective items please report the
          same to our Customer Service team. The request will, however, be
          entertained once the merchant has checked and determined the same at
          his own end. This should be reported within 30+ days of receipt of the
          products.
          <br />
          4. In case you feel that the product received is not as shown on the
          site or as per your expectations, you must bring it to the notice of
          our customer service within 30+ days of receiving the product. The
          Customer Service Team after looking into your complaint will take an
          appropriate decision.
          <br />
          5. In case of complaints regarding products that come with a warranty
          from manufacturers, please refer the issue to them.
          <br />
          6. Refund/Cancellation Policy: We will process the refund within 15 days of receiving the cancellation request. The refund will be credited back to the original payment method used during the purchase.
          <br />
        </p>
      </div>
    </>
  );
}

export default CnR;
