import React, { useEffect, useState } from "react";
import "../styles/aichatscreen.css";
import SearchIcon from "../assets/searchIcon.png";
import ProtectedRoute from "../components/ProtectedRoute";
// import io from "socket.io-client";
import { useParams } from "react-router-dom";

function AiChatScreen() {
  const { id } = useParams();
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  let socketInstance;
  const URL = "https://chat-service.ddns.net";

  const chatList = [
    { id: 1, name: "Chat 1" },
    { id: 2, name: "Chat 2" },
    { id: 3, name: "Chat 3" },
    { id: 4, name: "Chat 4" },
  ];

  //   useEffect(() => {
  //     // Connect to the Socket.io server
  //     socketInstance = io(URL, {
  //       withCredentials: true,
  //       transports: ["websocket"],
  //     });
  //     setSocket(socketInstance);

  //     // Fetch chat history when component mounts
  //     const roomId = id;
  //     const roomName = id;
  //     // const userId = users.userId;

  //     if (roomName.trim() && userId.trim()) {
  //       socketInstance.emit("joinRoom", roomName, userId);
  //     }

  //     socketInstance.emit("getChatHistory", roomId);

  //     // Listen for 'chatHistory' events and update the messages state
  //     socketInstance.on("chatHistory", (chatHistory) => {
  //       setMessages(chatHistory);
  //     });

  //     // Listen for 'message' events and update the messages state
  //     socketInstance.on("message", (newMessage) => {
  //       setMessages((prevMessages) => [...prevMessages, newMessage]);
  //     });

  //     console.log(messages);

  //     return () => {
  //       // Cleanup socket connection when component unmounts
  //       if (socketInstance && socketInstance.connected) {
  //         socketInstance.disconnect();
  //         console.log("Socket disconnected");
  //       }

  //       // Clean up event listeners here
  //       socketInstance.off("connect");
  //       socketInstance.off("disconnect");
  //       socketInstance.off("chatHistory");
  //       socketInstance.off("message");
  //     };
  //   }, [id, setMessages, users.userId]);

  //   const sendMessage = () => {
  //     if (socket && socket.connected && message.trim() !== "") {
  //       const roomId = id;
  //       const senderId = users.userId;
  //       const senderName = users?.userdata?.personalDetails?.first_name;

  //       const newMessage = {
  //         sender_id: senderId,
  //         sender_name: senderName,
  //         content: message,
  //         chatId: roomId,
  //       };

  //       // Update the messages state immediately
  //       //   setMessages((prevMessages) => [...prevMessages, newMessage]);

  //       // Send a 'sendMessage' event to the server
  //       socket.emit("sendMessage", roomId, newMessage);
  //       setMessage("");
  //     }
  //   };

  return (
    <ProtectedRoute>
      <div class="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen p-10">
        <div className="flex flex-row">
          <div className="w-[20vw] bg-[#FFC57BF2] rounded-2xl">
            <div className="p-5 text-2xl font-semibold">Chats</div>

            <div className="flex justify-center">
              <div className="relative w-56">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <img src={SearchIcon} alt="Search Icon" className="h-4 w-4" />
                </span>
                <input
                  type="text"
                  placeholder="Search chats"
                  className="pl-8 pr-3 py-2 w-full rounded-lg border border-gray-300"
                />
              </div>
            </div>

            {chatList.map((item) => (
              <div
                key={item.id}
                className={`flex mt-3 cursor-pointer mx-3 ${
                  selectedChat === item.id ? "bg-red-500 h-8" : ""
                }`}
                onClick={() => setSelectedChat(item.id)}
              >
                <div className="ml-10 text-center flex items-center justify-center">{item.name}</div>
              </div>
            ))}

            <div className="flex justify-center items-center bg-[#00000040] mx-3 h-10 text-center">
            + New Chat
            </div>
          </div>

          <div className="w-[70vw]">
            <div class="flex sm:items-center justify-between py-3 border-b-2 border-gray-200 ml-10"></div>
            <div
              id="messages"
              class="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch mt-96"
            >
              {/* {
            {messages.map((elem, i) => (
              <div
                key={i}
                style={users.userId === elem.senderId ? { alignSelf: "flex-end" } : { alignSelf: "flex-start" }}
                className='ai_chats_container_chats_message'
              >
                {elem.sender_name}:{elem.content}
              </div>
            ))}
          } */}
              {messages.map((elem, i) => (
                <div className="chat-message" key={i}>
                  <di className="text-black text-base">{elem?.sender_name}</di>
                  <div className={`flex items-end ${"justify-end"}`}>
                    <div
                      className="flex flex-col space-y-2 text-xl max-w-xs mx-2 
                       order-2 items-start
                  "
                    >
                      <div>
                        <span
                          className={`px-4 py-2 rounded-lg inline-block ${
                            elem.senderId
                              ? "rounded-bl-none bg-gray-300 text-gray-600"
                              : "rounded-br-none bg-blue-600 text-white"
                          }`}
                        >
                          {elem.content}
                        </span>
                      </div>
                    </div>
                    <img
                      src={"https://www.w3schools.com/howto/img_avatar.png"}
                      alt="My profile"
                      className={`w-6 h-6 rounded-full ${
                        elem.senderId ? "order-2" : "order-1"
                      }`}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div class="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0 items-center ml-10">
              <div class="relative flex items-center justify-between space-x-5">
                <input
                  type="text"
                  placeholder="Write your message!"
                  class="w-full h-12 focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button
                  type="button"
                  class="inline-flex items-center justify-center rounded-xl px-4 py-5 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none mb-2"
                  //   onClick={sendMessage}
                >
                  <span class="font-bold">Send</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="h-6 w-6 ml-2 transform rotate-90"
                  >
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='ai_chats_container'>
        <h3 className='ai_chats_container_hed'>Batch Chats</h3>
        <div className='ai_chats_container_chats'>
          {messages.map((elem, i) => (
            <div
              key={i}
              style={users.userId === elem.senderId ? { alignSelf: "flex-end" } : { alignSelf: "flex-start" }}
              className='ai_chats_container_chats_message'
            >
              {elem.sender_name}:{elem.content}
            </div>
          ))}
        </div>
        <div className='ai_chats_container_send_message'>
          <textarea
            placeholder='Enter your message here ...'
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className='ai_chats_container_send_message_inp'
          />
          <button onClick={sendMessage} className='ai_chats_container_send_message_btn'>Send</button>
        </div>
      </div> */}
    </ProtectedRoute>
  );
}

export default AiChatScreen;
