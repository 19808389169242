import React, { useState } from "react";
import "../styles/navbar.css";
import { LuArrowDownCircle } from "react-icons/lu";

import logo from "../assets/icons/logo1.png";
import hamburger from "../assets/icons/hamburger.png";

import { useNavigate } from "react-router-dom";
import { curriculams } from "../utils/data";

import Select from "react-select";
import whatsAppIcon from "../assets/icons/WhatsApp.png";

function Navbar() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  const [grade, setSelectedGrade] = useState(curriculams[0]);

  const [curriculamsDropdown, setcurriculamsDropdown] = useState(false);
  const [loginDropdown, setloginDropdown] = useState(false);
  const toggleNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleToTeacher = () => {
    const teacherPanel = "https://teacher.intelliedtech.com/";
    window.open(teacherPanel, "_blank");
    setShowModal(!showModal);
  };
  const handleToWhasAppGrp = () => {
    const whatsAppGroupLinl =
      "https://www.whatsapp.com/channel/0029Va9MAQHEQIalAIoRJl1l";
    window.open(whatsAppGroupLinl, "_blank");
  };
  const handleToStudent = () => {
    const student = "https://student.intelliedtech.com/";
    window.open(student, "_blank");
    setShowModal(!showModal);
  };
  const handleToInstitute = () => {
    const institute = "https://institute.intelliedtech.com/";
    window.open(institute, "_blank");
    setShowModal(!showModal);
  };
  const loginOptions = [
    { label: "Student", value: "Student" },
    { label: "Teacher", value: "Teacher" },
    { label: "Institute", value: "Institute" },
  ];
  return (
    <>
      <img
        onClick={() => {
          toggleNavbar();
        }}
        className="navbar_part_1_hamburger"
        src={hamburger}
        alt="hamburger"
      />
      <div className="navbar">
        <div className="navbar_part_1">
          <img
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            className="navbar_logo"
          />
        </div>
        <div className="navbar_part_2">
          <ul className="navbar_part_2_list">
            <li
              onClick={() => {
                navigate("/");
              }}
              className="navbar_list_item"
            >
              Home
            </li>

            <li
              onClick={() => {
                navigate("/doubt-solving");
              }}
              className="navbar_list_item"
            >
              Doubt Solving
            </li>
            <li
              onClick={() => {
                navigate("/online-tutoring");
              }}
              className="navbar_list_item"
            >
              Online Tutoring
            </li>
            <li
              onClick={() => {
                navigate("/home-tutoring");
              }}
              className="navbar_list_item"
            >
              Home Tutoring
            </li>
            <li
              onClick={() => {
                // navigate("/resource-hub");
                navigate("/questions");
              }}
              className="navbar_list_item"
            >
              Resource Hub
            </li>
            <li
              onClick={() => {
                navigate("/exam-series");
              }}
              className="navbar_list_item"
            >
              Exam Series
            </li>
            <li
              onClick={() => {
                navigate("/intelli-ai");
              }}
              className="navbar_list_item"
            >
              Intelli AI{" "}
            </li>
            <li
              onClick={() => {
                navigate("/questionBank");
              }}
              className="navbar_list_item"
            >
              Question Bank{" "}
            </li>
            {/* <li
              onMouseEnter={() => {
                setcurriculamsDropdown(true);
              }}
              onMouseLeave={() => {
                setcurriculamsDropdown(false);
              }}
              className="navbar_list_item"
             >
              Question Bank{" "}
              <LuArrowDownCircle
                className="navbar_icons_logo"
                color="#FA991C"
              />
              {curriculamsDropdown ? (
                <div
                  onMouseLeave={() => {
                    setcurriculamsDropdown(false);
                  }}
                  onMouseEnter={() => {
                    setcurriculamsDropdown(true);
                  }}
                  className="navbar_dropdown"
                >
                  {curriculams.map((elem, i) => {
                    return <h5>{elem.value}</h5>;
                  })}
                </div>
              ) : null}
            </li> */}
          </ul>
          <li
            onMouseEnter={() => {
              setloginDropdown(true);
            }}
            onMouseLeave={() => {
              setloginDropdown(false);
            }}
            className="navbar_list_item"
          >
            Login{" "}
            <LuArrowDownCircle className="navbar_icons_logo" color="#FA991C" />
            {loginDropdown ? (
              <div
                onMouseLeave={() => {
                  setloginDropdown(false);
                }}
                onMouseEnter={() => {
                  setloginDropdown(true);
                }}
                className="navbar_dropdown"
              >
                {loginOptions.map((elem, i) => {
                  return (
                    <h5
                      onClick={() => {
                        if (elem.value == "Student") {
                          handleToStudent();
                        } else if (elem.value == "Teacher") {
                          handleToTeacher();
                        } else {
                          handleToInstitute();
                        }
                      }}
                    >
                      {elem.value}
                    </h5>
                  );
                })}
              </div>
            ) : null}
          </li>
        </div>
        <li
          onClick={() => {
            navigate("/contact-us");
          }}
          className="navbar_list_item"
        >
          Contact Us
        </li>

        <img
          onClick={() => {
            handleToWhasAppGrp();
          }}
          src={whatsAppIcon}
          className="navbar_icon_whatsapp"
        />
      </div>
      {/* {
          showModal?
          <div className='navbar_modal_login'>
        <button onClick={()=>{handleToStudent()}}  className='navbar_modal_login_btn'>Student</button>
        <button onClick={()=>{handleToTeacher()}}  className='navbar_modal_login_btn'>Teacher</button>

            </div>
            :null
        } */}
      <div
        style={{ display: showNavbar ? "flex" : "none" }}
        className="navbar_resp"
      >
        <div className="navbar_resp_part_1">
          <img
            onClick={() => {
              toggleNavbar();
              setShowModal(false);
            }}
            className="navbar_part_1_hamburger"
            src={hamburger}
            alt="hamburger"
          />
          <img
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            className="navbar_logo"
          />
        </div>
        <ul className="navbar_part_2_list">
          <li
            onClick={() => {
              navigate("/");
            }}
            className="navbar_list_item"
          >
            Home
          </li>

          <li
            onClick={() => {
              navigate("/doubt-solving");
            }}
            className="navbar_list_item"
          >
            Doubt Solving
          </li>
          <li
            onClick={() => {
              navigate("/online-tutoring");
            }}
            className="navbar_list_item"
          >
            Online Tutoring
          </li>
          <li
            onClick={() => {
              navigate("/home-tutoring");
            }}
            className="navbar_list_item"
          >
            Home Tutoring
          </li>
          <li
            onClick={() => {
              navigate("/resource-hub");
            }}
            className="navbar_list_item"
          >
            Resource Hub
          </li>
          <li
            onClick={() => {
              navigate("/exam-series");
            }}
            className="navbar_list_item"
          >
            Exam Series
          </li>
          <li
            onClick={() => {
              navigate("/intelli-ai");
            }}
            className="navbar_list_item"
          >
            Intelli AI I
          </li>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: "150px",
                borderColor: "#FA991C",
                backgroundColor: "transparent",
                cursor: "pointer",
                textAlign: "center",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#032539",
              }),
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: "#032539",
                };
              },
            }}
            className="navbar_list_item"
            placeholder="Question bank"
            options={curriculams}
            onChange={(selectedOption) =>
              setSelectedGrade(selectedOption.value)
            }
          />
        </ul>
        <li
          onClick={() => {
            navigate("/contact-us");
          }}
          className="navbar_list_item"
        >
          Contact Us
        </li>
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              minWidth: "150px",
              borderColor: "#FA991C",
              cursor: "pointer",
              backgroundColor: "transparent",
              textAlign: "center",
            }),
            dropdownIndicator: (baseStyles, state) => ({
              ...baseStyles,
              color: "#032539",
            }),
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                color: "#032539",
              };
            },
          }}
          value="Login"
          className="navbar_list_item"
          placeholder="Login"
          options={loginOptions}
          onChange={(selectedOption) => {
            if (selectedOption.value == "Student") {
              handleToStudent();
            } else if (selectedOption.value == "Teacher") {
              handleToTeacher();
            } else {
              handleToInstitute();
            }
          }}
        />
      </div>
      <img
        onClick={() => {
          handleToWhasAppGrp();
        }}
        src={whatsAppIcon}
        className="navbar_icon_whatsappRes"
      />
    </>
  );
}

export default Navbar;
