import React from 'react'
import CoverBackground from '../components/CoverBackground'
import "../styles/service.css"
import HomeTutoringComp from '../components/HomeTutoringComp'
import InstructionVideo from '../components/InstructionVideo'

function HomeTutor() {
  return (
    <div className='main_container'>
    <CoverBackground />
    <div className='main_container_part'>
    <HomeTutoringComp />
    <InstructionVideo />
     </div>
     </div>
  )
}

export default HomeTutor
