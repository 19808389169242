import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../styles/service.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from 'react-responsive-modal';
import loader from "../assets/loader/loader.gif"
import ProtectedRoute from '../components/ProtectedRoute';

const Exam = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [score, setScore] = useState(0);
  const navigate = useNavigate();
  const [modalInfo, setModalInfo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/content-pannel/v1/generateQuestion/questions?questionType=multipleChoice&exam=64e5cc1663e90ceb717e2e83&subject=64e5dbe7e4129565cd47e532&chapter=6539f6d3a162555b15089a55&topic=6539f704a162555b15089a5d&subTopic=6539f845a162555b15089a91&numOfQuestions=5';
        const response = await axios.get(apiUrl);

        setQuestions(response.data);
        setLoading(false); // Set loading to false when data is received
      } catch (error) {
        console.error('Error fetching questions:', error);
        setLoading(false); // Set loading to false in case of an error
        toast.error('Failed to fetch questions. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleOptionChange = (questionIndex, optionIndex) => {
    setSelectedOptions({
      ...selectedOptions,
      [questionIndex]: optionIndex,
    });
  };

  const handleNextClick = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handleSkipClick = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    // Handle skip logic if needed
  };

  const handlePreviousClick = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleSubmitClick = () => {
    // Calculate score based on selected options and correct answers
    let calculatedScore = 0;
    questions.forEach((question, index) => {
      const correctOptionIndex = question.correctAnswer;
      const selectedOptionIndex = selectedOptions[index];
      if (correctOptionIndex === selectedOptionIndex) {
        calculatedScore += 1;
      }
    });

    // Display the score
    setScore(calculatedScore);
    setShowModal(true);
  };

  const handleFinishExam = () => {
    // Handle any logic needed before finishing the exam
    navigate('/');
  };

  return (
    <ProtectedRoute>

    <div className='main_container'>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        center
        styles={{
          modal: { width: "40%", height: "45%", borderRadius: "10px" },
        }}
      >
        <div>
          <h2>Your Score</h2>
          <p>{`Your score: ${score}/${questions.length}`}</p>
          <button className='exam_result_btn' style={{marginTop:'5%'}} onClick={handleFinishExam}>Finish</button>
        </div>
      </Modal>
   <div className='main_container_attempt_exam'>
      <h1>Exam Questions</h1>
    {loading && <div style={{width : '100%' ,  justifyContent :'center', alignItems:'center', display:'flex' }}>
    <img  style={{height:'70px'}}  src={loader} />
     </div>}

      {questions.length > 0 && (
        <div >
          <div dangerouslySetInnerHTML={{ __html: questions[currentQuestionIndex].question }} />

          {questions[currentQuestionIndex].options.map((option, optionIndex) => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} key={optionIndex}>
              <input
                type="radio"
                style={{ marginBottom: '30px' }}
                name={`question_${currentQuestionIndex}`}
                value={option}
                checked={selectedOptions[currentQuestionIndex] === optionIndex}
                onChange={() => handleOptionChange(currentQuestionIndex, optionIndex)}
              />
              <div dangerouslySetInnerHTML={{ __html: option }} />
            </div>
          ))}
          <br />
          <div className='main_container_attempt_exam_buttons'>
            {currentQuestionIndex > 0 && (
              <button onClick={handlePreviousClick}>Previous</button>
            )}
            {currentQuestionIndex < questions.length - 1 && (
              <button  onClick={handleNextClick}>Submit & Next</button>
            )}
            {currentQuestionIndex !== questions.length - 1 && (
              <button  onClick={handleSkipClick}>Skip</button>
            )}
            {currentQuestionIndex === questions.length - 1 && (
              <button  onClick={handleSubmitClick}>Submit</button>
            )}
          </div>
        </div>
      )}
    </div>
   </div>
   </ProtectedRoute>

  );
};

export default Exam;
