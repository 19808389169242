import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/Tnc.css";
function About() {
  return (
    <>
      <div className="main_div">
        <h1 className="privacy_heading">Vision </h1>
        <p className="paragraph">
        Revolutionizing Education, Empowering Minds

          <br />
          <br />
          <br />
          At Intelliedtech, our vision is to create a future where education knows no bounds, and every learner
can access personalized, high-quality learning experiences. We aspire to empower minds, foster
innovation, and enable students to excel beyond their potential. Our vision is grounded in leveraging
advanced technology to democratize education, making it accessible, engaging, and tailored to
individual needs.
        </p>
        <h1 className="privacy_heading">Mission </h1>

        <p className="paragraph">
        Empowering the World's Learners

          <br />
          <br />
          <br />
          Our mission is to provide an inclusive, holistic, and engaging learning ecosystem that empowers
students and educators across the globe. We are dedicated to breaking down traditional barriers to
education and fostering a sense of community among learners. We aim to equip students with the
skills and knowledge they need to thrive in a rapidly evolving world. Our mission is guided by:


</p>

        <p className="paragraph">
          <br />
          1. Personalized Learning & Tutorless Teaching with AI/ML: We are committed to offering
personalized, tutorless learning experiences powered by AI and ML. Every student's journey
is unique, and we aim to adapt to their learning needs and preferences.
          <br />
          2.Home Tuition via an Interactive App (Uber Model): We are aspire to connect best teachers to
the students for home tutions where parents can monitor the students and also judge the
teachers.

          <br />
          3.Accessibility: We strive to make education accessible to all, irrespective of geographical or
socio-economic constraints. Our platform bridges the gap between expert educators and
eager learners, no matter where they are.
          <br />
          4. Augmented Reality for Immersive Learning: We aspire to create a 3d based interactive
content on our platform for a best learning experience. We will be making models for various
subjects so that students can easily revise and memorise the topics. 
          <br />
          5.Innovation: We are dedicated to pushing the boundaries of educational technology. Our
journey includes integrating AR technologies for immersive 3D content and continually
exploring emerging technologies to enhance the learning experience.
          <br />
          6.Community Building: We are building a global community of learners who can connect,
collaborate, and grow together. Our focus is on fostering peer support, knowledge sharing,
and meaningful interactions.
          <br />
          
        </p>
        <h1 className="privacy_heading">Goals </h1>
        <p className="paragraph">
          <br />
          1.Global Reach: We aim to reach learners in every corner of the world, breaking language and
geographical barriers through multilingual content.
          <br />
          2.Millions of Lives Impacted: Our goal is to positively impact the lives of millions of learners,
equipping them with skills and knowledge that prepare them for the challenges and
opportunities of the future.
<br/>
3.. Industry-Leading Technology: We strive to maintain our position as an industry leader in
EdTech, consistently offering innovative features such as real-world simulations, gamified
learning, and virtual laboratories.
<br/>
4.Data Privacy and Security: We are committed to ensuring the highest standards of data
privacy and security for our users.
          </p>

        <h1 className="privacy_heading">Innovative Features </h1>
        <p className="paragraph">
          <br />
          1. 24/7 On-Demand Support: We offer continuous support to ensure that learning never stops.
          <br />

2. Gamified Learning: We make learning fun and engaging by incorporating gamified elements
into our platform.
<br />

3. Real-world Simulations: Our platform provides real-world simulations in subjects like science,
business, and technology.
<br />

4. Multilingual Content: We offer educational content in multiple languages to make learning
accessible to diverse communities.
<br />

5. Personalized Learning Paths: Our platform creates individualized learning paths for students,
optimizing their learning journey.
<br />

6. Advanced Analytics for Educators: Our analytics tools empower teachers to track student
progress and personalize their teaching strategies.
<br />

7. Interactive Laboratories: We provide state-of-the-art virtual laboratories based on AR
technologies for hands-on learning experiences.
<br />

8. Continuous Skill Assessment: We believe in lifelong learning and ensure that students and
teachers stay updated with industry standards.
<br />

9. Seamless Integration with E-learning Platforms: Our platform seamlessly integrates with
popular e-learning systems.
<br />

10. Virtual Career Counselling: Our AI-driven career counseling assists students in making
informed decisions about their future.
<br />

          </p>
      </div>
    </>
  );
}

export default About;
