import React, { useState } from "react";
import "./allReview.css";
import Modal from "react-responsive-modal";
import { decodeHtmlEntities } from "../../../utils/config";

const Objective = ({ data, button, type }) => {
  // console.log("data----------", data);
  const [showMarkSchemesModal, setShowMarkSchemesModal] = useState(false);

  const handleMarkSchemes = (question) => {
    setShowMarkSchemesModal(true);
  };

  const handleCloseMarkSchemesModal = () => {
    setShowMarkSchemesModal(false);
  };

  let dataAns = data?.options
    ?.filter((i) => i?.isCorrect === true)
    .map((i) => i.text);

  return (
    <>
      <Modal
        open={showMarkSchemesModal}
        onClose={handleCloseMarkSchemesModal}
        center
        styles={{
          modal: {
            width: "95%",
            height: "80%",
            borderRadius: "10px",
            backgroundColor: "#E3F2FD",
          },
        }}
      >
        <div
          style={{
            flex: 1,
            padding: "30px",
            overflowY: "auto",
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: 10,
            marginTop: 30,
          }}
        >
          <h3>Question</h3>
          <div
            className="question-content"
            dangerouslySetInnerHTML={{
              __html: data?.question,
            }}
          ></div>
        </div>

        <div
          style={{
            flex: 1,
            padding: "30px",
            overflowY: "auto",
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: 10,
            marginTop: 30,
          }}
        >
          <div className="options-container">
            <p className="text">Correct Option: </p>&nbsp;&nbsp;&nbsp;&nbsp;
            {data?.questionType === "mcqmultiple" ? (
              <>
                {dataAns?.map((i) => {
                  return (
                    <div className="optionAll bgStyle">
                      {decodeHtmlEntities(i)}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="optionAll bgStyle">
                <div
                  className="question-content"
                  dangerouslySetInnerHTML={{
                    __html: dataAns,
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <div className="flex justify-between">
        <div style={{ width: "75%" }} className="items-center gap-5">
          <div className="flex-1">
            <div className="question-box w-full">
              <div
                className="question-content"
                dangerouslySetInnerHTML={{
                  __html: data?.question,
                }}
              ></div>
            </div>
          </div>
          <div>
            <div className="options-style">
              {data?.options?.map((item) => {
                return (
                  <div className="optionAll">
                    {
                      <div
                        className="question-content"
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      ></div>
                    }
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-0.5 bg-gray-200"></div>
        <div className="w-64">
          <div>
            <div style={{ color: "black" }}>
              Minimum Marks:
              {data.mark}
            </div>
            <div style={{ marginTop: 10 }}>
              Negative Marks:
              {/* {data.level} */}
            </div>
            <div className="flex" style={{ marginTop: 10 }}>
              Question Type: &nbsp;
              <div
                style={{
                  color:
                    data?.level === "Easy"
                      ? "#3771C8"
                      : data?.level === "Medium"
                      ? "#1FAF38"
                      : "#F71D2C",
                }}
              >
                {data.level}
              </div>
            </div>

            <div
              style={{
                backgroundColor:
                  data.level === "Easy"
                    ? "rgba(0, 0, 255, 0.3)"
                    : data.level === "Medium"
                    ? "rgba(0, 255, 0, 0.5)"
                    : data.level === "Hard"
                    ? "rgba(243, 76, 48, 0.96)"
                    : "transparent",
                width:
                  data.level === "Easy"
                    ? "30%"
                    : data.level === "Medium"
                    ? "45%"
                    : data.level === "Hard"
                    ? "90%"
                    : "0%",
                borderWidth: 1,
                borderColor:
                  data.level === "Easy"
                    ? "rgba(0, 0, 255, 0.3)"
                    : data.level === "Medium"
                    ? "rgba(0, 255, 0, 0.5)"
                    : data.level === "Hard"
                    ? "rgba(243, 76, 48, 0.96)"
                    : "transparent",
                borderStyle: "solid",
                borderRadius: "9px",
                marginBottom: "10px",
                padding: "12px",
                marginTop: "10px",
              }}
            />
            <div className="flex flex-col">
              <button
                onClick={() => handleMarkSchemes(data)}
                style={{
                  padding: "8px 5px",
                  fontSize: "14px",
                  borderRadius: "10px",
                  fontWeight: "400",
                  marginTop: "10px",
                  color: "black",
                  backgroundColor: "#BAB5EA",
                }}
              >
                <span role="img" aria-label="PDF icon" className="mr-1">
                  📄
                </span>
                Mark Scheme
              </button>
              {button}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Objective;
