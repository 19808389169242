import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../utils/config";
import "../../styles/payment.css";
import { RazorPayment } from "./RazorPayment";

function Payment() {
    const {id} = useParams(); 
    console.log('id', id);
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [transactionPayment, setTransactionPayment] = useState("")

  const handlePayClick = () => {
        RazorPayment(data)
            .then((result) => {
                if (result.status) {
                    setTransactionPayment(result)
                    window.location.href = "https://www.intelliedtech.com"
                }
            })
            .catch((error) => {
                alert(error.message);
            });
    };

  const paymetHandler = async () => {
    let data = JSON.stringify({
      amount: 100,
      currency: "INR",
      receipt: "Tution",
      name: "Amit",
      mobileNumber: "8219090",
    });

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.Razor}/api/order/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res", res.data);
        setData(res.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    paymetHandler()
  }, []);

  return (
         <div className="admin_panel_resource_container">
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <label className="text-[#4A3794]" htmlFor="username">
                Name Of Payee
              </label>
              <input
              value={data.name}
                id="username"
                type="text"
                required={true}
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Name of Payee"
              />
            </div>
            <div className="mt-1">
              <label className="text-[#4A3794]" htmlFor="username">
                Amount Required
              </label>
              <input
              value={data.amount}
                id="username"
                type="number"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Amount in (RS.)"
                required={true}
              />
            </div>
            <div className="mt-1">
              <label className="text-[#4A3794]" htmlFor="username">
                Name Of Service
              </label>
              <input
              value={data.receipt}
                id="username"
                type="text"
                required={true}
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Name of the service"
              />
            </div>
            <div className="mt-1">
              <label className="text-[#4A3794]" htmlFor="username">
                Mobile Number
              </label>
              <input
              value={data.mobileNumber}
                id="username"
                type="number"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Mobile Number"
                required={true}
              />
            </div>

            {/* <div className="mt-1">
              <label className="text-[#4A3794]" htmlFor="username">
                Email
              </label>
              <input
              value={data.email}
                id="username"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Your Email"
                required={true}
              />
            </div> */}
          </div>
        
        
        <div 
        onClick={handlePayClick}
        className="bg-[#3E80E7] text-white cursor-pointer flex items-center justify-center p-5 self-center mt-10 rounded-md">Pay Now</div>
      </div>
  );
}
export default Payment;
