import { useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ProtectedRoute({ children }) {

const navigate=useNavigate()
  const expirationDate = new Date( localStorage.getItem("tokenExpiresIn"));
  const currentTime = new Date();
  

  useEffect(() => {

if(!localStorage.getItem("isLoggedIn") || localStorage.getItem("isLoggedIn")=="false"){
toast.error("Login In Order To Access This Page !")
navigate("/")

}
    else if (localStorage.getItem("tokenExpiresIn") != null && currentTime >= expirationDate) {

          localStorage.setItem("accessToken",'')
          localStorage.setItem("isLoggedIn","false")
          localStorage.setItem("tokenExpiresIn",'')
          navigate("/")
    }
  }, [ navigate])

  return children;


}

export default ProtectedRoute
