import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/hero.css";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function HeroSection() {
  const [phone, setPhone] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");

  const [mode, setMode] = useState("online");
  const [loading, setLoading] = useState(false);
  const [curriculum, setCurriculum] = useState([]);
  const [activeFlyer, setActiveFlyer] = useState(null);
  const [subTopic, setSubTopic] = useState(false);
  const [currentTopic, setCurrentTopic] = useState("");
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const BaseUrl = "https://4i3482jteb.execute-api.ap-south-1.amazonaws.com";

  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalHandleSubmit = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
    setSelectedTopic(null);
    setSubTopic(true);
    setCurrentTopic(subject.name);
  };


  useEffect(() => {  
    // Fetch all curriculum data when the component mounts
    const fetchAllCurriculum = async () => {
      setLoading(true); 
      try {
        const response = await axios.get(
          `${BaseUrl}/dev/content-pannel/v1/curri/curriculums`
        );
        const data = response.data;
        console.log(data);
        setCurriculum(data);
        setActiveFlyer(0);
      } catch (error) {
        console.error("Error fetching curriculum data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllCurriculum();
  }, []); // The empty dependency array ensures that this effect runs only once on mount

  const handleFlyerClick = (index) => {
    setActiveFlyer(index === activeFlyer ? null : index);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!name || !email || !email || !query || !phone) {
      toast.error("All fields are mandatory. Please fill in all the details.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${BaseUrl}/dev/user-mgmt-service/ums/apis/v1/users/query`,
        {
          name,
          email,
          phone,
          query,
        }
      );
      console.log("API Response:", response.data);
      setLoading(false);
      toast.success("Your request is submitted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Auto close the toast after 3 seconds
      });
      setPhone("");
      setEmail("");
      setQuery("");
      setName("");
    } catch (error) {
      setLoading(false);
      toast.error("Error submitting your request. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      console.error("Error:", error);
    }
  };

  return (
    <div className="hero_section_copy">
      <div className="hero_section_part_1 mx-20 ">
        <div className="hero_section_in_1 hidden lg:flex justify-center relative">
          <nav className="hero_section_in_2 hidden lg:flex space-x-1 mx-auto">
            {curriculum.map((item, index) => (
              <div key={item._id}>
                <button
                  type="button"
                  className={`group bg-[var(--lightYellow)] p-2 bg-white rounded-md text-white inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer ${
                    activeFlyer === index ? "text-gray-900" : "text-gray-500"
                  }`}
                  onClick={() => handleFlyerClick(index)}
                >
                  <span className="text-black font-bold tracking-tighter">
                    {item.name}
                  </span>
                  <svg      
                    className={
                      activeFlyer === index
                        ? "transform rotate-180 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-out duration-200"
                        : "ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {activeFlyer === index && (
                  <div
                    className="opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 "
                    // lg:left-1/2
                    // lg:-translate-x-1/2

                    style={{
                      opacity: 1,
                      left: "0%",
                      transform: [{ translateX: "-50%" }],
                    }}
                  >
                    <div
                      className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
                      style={{
                        width: "732px",
                        height: "580px",
                        overflow: "hidden",
                        display: "flex",
                        overflowY: "auto",
                        overflowX: "auto",
                      }}
                    >
                      <div
                        className=" grid gap-2 px-4 py-6 sm:gap-8"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingBottom: 580,
                        }}
                      >
                        {item.topics.length > 0 ? (
                          item.topics.map((topic) => (
                            <div key={topic._id}>
                              <h2 className="text-lg font-bold mb-2">
                                {topic.name}
                              </h2>
                              <ul>
                                {topic.subtopics.map((subtopic) => (
                                  <>
                                    <li
                                      key={subtopic._id}
                                      className="cursor-pointer  color: var(--textLight) hover:text-blue-500"
                                    >
                                      {subtopic.name}
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </div>
                          ))
                        ) : (
                          <p>No topics available.</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>

      <div className="hero_section_part_2">
        <div className="modal_div">
          {/* <h1>Quick Query </h1> */}
          <h1>Crack your goal with India's top Educators</h1>
          <p style={{ fontWeight: "bold", fontSize: 20 }}>
            Select the Session Mode
          </p>
          <div className="modal_div_mode">
            <div
              style={{ backgroundColor: mode == "online" ? "#FA991C" : null }}
              onClick={() => {
                setMode("online");
              }}
              className="modal_div_mode_part"
            >
              Online
            </div>
            <div
              style={{ backgroundColor: mode == "offline" ? "#FA991C" : null }}
              onClick={() => {
                setMode("offline");
              }}
              className="modal_div_mode_part"
            >
              Offline
            </div>
          </div>
          <p>Enter Your Details</p>

          {/* <input value={phone} onChange={(e) => { setPhone(e.target.value) }} placeholder='Enter Your Mobile Nunber Here ' type="number" className='hero_section_part_2_inp' /> */}
          <label>Name</label>
          <input
            className="modal_div_inp_c"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            type="text"
            placeholder="Enter Your Name"
          />
          <label>Email</label>

          <input
            className="modal_div_inp_c"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
            placeholder="Enter Your Email ID"
          />
          <label>Mobile</label>

          {/* <input
            className="modal_div_inp_c"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            type="number"
            placeholder="Enter Your Phone Number"
          /> */}
          <PhoneInput
            country={"in"} // Default country code
            value={phone}
            onChange={(value, country, e, formattedValue) => setPhone(value)}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
              placeholder: "Enter Your Phone Number",
            }}
            containerStyle={{
              // Add any additional styling for the PhoneInput container if needed
              width: "94%",
              marginTop: "10px", // Example: add top margin
              outline: "none",
              borderRadius: "10px",
              color: "var(--textLight)",
              boxShadow: "3px 3px rgb(221, 215, 215)",
              margin: "10px 10px",
              padding: "10px",
              backgroundColor: "transparent",
              fontSize: "15px",
            }}
            inputStyle={{
              // Add any additional styling for the PhoneInput input if needed
              backgroundColor: "#fff", // Example: set background color
              outline: "none",
              border: "0",
              color: "var(--textLight)",
            }}
          />

          <label>Query</label>

          <textarea
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            type="text"
            placeholder="Enter Your Query "
          />
          <button
            onClick={() => {
              handleSubmit();
            }}
          >
            Quick Connect
          </button>
        </div>

        <div className="hero_subject flex bg-gray-100">
          <div className="max-w-screen-lg w-full">
            <button
              onClick={modalHandleSubmit}
              className="hero_subject_1 md:hidden text-white bg-[#6B4EFF] text-xl p-5"
            >
              Select Curriculum and Subject
            </button>

            {subTopic ? (
              <div
                className={`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50 overflow-y-scroll ${
                  isModalOpen ? "block" : "hidden"
                }`}
              >
                <div className="w-full h-full flex flex-col bg-white rounded-lg overflow-hidden">
                  <div className="flex flex-row items-center justify-center p-8 bg-[#FFC226]">
                    <button
                      className="text-gray-500 text-4xl"
                      onClick={() => {
                        setSubTopic(false);
                        setCurrentTopic("");
                      }}
                    >
                      {"<"}
                    </button>
                    <div className="text-2xl text-[#250075] flex-grow text-center">
                      Select Subject
                    </div>
                  </div>

                  <div className="p-8 flex-grow bg-blue-500 overflow-y-scroll">
                    <div className="text-red flex item-center justify-center text-2xl py-2 text-white">
                      {currentTopic}
                    </div>

                    {curriculum?.map((subject, index) => {
                      if (subject.name === currentTopic) {
                        return subject.topics.length > 0 ? (
                          <div key={index}>
                            {subject.topics.map((topic) => (
                              <div key={topic._id} className="ml-1">
                                <h2 className="text-2xl mb-2 text-white my-7 flex items-center">
                                  {/* Larger bullet character */}
                                  <span className="text-4xl mr-2">&#8226;</span>
                                  {topic.name}
                                </h2>
                                <ul>
                                  {topic.subtopics.map((subtopic) => (
                                    <h2
                                      key={subtopic._id}
                                      className="text-white text-xl flex items-center"
                                    >
                                      {/* Larger bullet character */}
                                      <span className="text-2xl mr-2">
                                        &#8226;
                                      </span>
                                      {subtopic.name}
                                    </h2>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="text-2xl mt-3 items-center justify-center flex">
                            <p key={index}>
                              No topics available for {subject.name}.
                            </p>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50 ${
                  isModalOpen ? "block" : "hidden"
                }`}
              >
                <div className="w-full h-full overflow-y-auto  flex-grow">
                  <div className="flex flex-row items-center justify-center p-8 bg-[#FFC226]">
                    <button
                      className="text-gray-500 text-4xl"
                      onClick={handleCloseModal}
                    >
                      {"<"}
                    </button>
                    <div className="text-2xl text-[#250075] flex-grow text-center">
                      Select Curriculum
                    </div>
                  </div>

                  <div className="p-8 h-full overflow-y-scroll bg-blue-500">
                    {curriculum?.map((subject, index) => (
                      <div
                        key={index}
                        onClick={() => handleSubjectClick(subject)}
                        className="text-red flex item-center justify-center text-2xl py-2 text-white cursor-pointer"
                      >
                        {subject?.name}
                      </div>
                      
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
