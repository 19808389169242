// import React, { useState, useEffect, useRef } from "react";
// import "./drop.css";

// export const DropDown = ({ optionsList, fieldName, formData, setFormData }) => {
//   console.log("optionsList----------", optionsList);
//   const [isOpen, setIsOpen] = useState(false);
//   const dropdownRef = useRef(null);

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   // const handleChange = (event) => {
//   //   // console.log("e-------", event);
//   //   const { value, checked } = event.target;
//   //   const updatedValues = checked
//   //     ? [...formData[fieldName], value]
//   //     : formData[fieldName].filter((item) => item !== value);
//   //   setFormData({
//   //     ...formData,
//   //     [fieldName]: updatedValues,
//   //   });
//   // };

//   const handleChange = (event) => {
//     const { value, checked } = event.target;

//     if (value === "All") {
//       if (checked) {
//         const allValues = optionsList.map((option) => option.value);
//         setFormData({
//           ...formData,
//           [fieldName]: allValues,
//         });
//       } else {
//         setFormData({
//           ...formData,
//           [fieldName]: [],
//         });
//       }
//     } else {
//       const updatedValues = checked
//         ? [...formData[fieldName], value]
//         : formData[fieldName].filter((item) => item !== value);

//       setFormData({
//         ...formData,
//         [fieldName]: updatedValues,
//       });
//     }
//   };

//   const handleClickOutside = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setIsOpen(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const getOptionDisplayName = (option) => {
//     // console.log("crystal------", option);
//     // return `${option.examName} - ${option?.chapterName} - ${option?.gradeName}-${option?.subjectName}-${option?.topicName}-${option?.subTopicName}`;
//     // if (option?.examName) return option?.examName;
//     // if (option?.gradeName) return option?.gradeName;
//     if (option?.subjectName) return option?.subjectName;
//     // if (JSON.stringify(option?.chapterName, null, 2))
//     //   return JSON.stringify(option?.chapterName, null, 2);
//     if (option?.chapterName) return option?.chapterName;

//     if (option?.topicName) return option?.topicName;
//     if (option?.subTopicName) return option?.subTopicName;
//     if (option?.diificultyLevel) return option?.diificultyLevel;
//     if (option?.papers) return option?.papers;

//     if (option) return option;
//     return option?.name || option?._id || option?.value || "Unnamed"; // Fallback to _id or generic display
//   };

//   return (
//     <div className="dropdown" ref={dropdownRef}>
//       <button className="dropbtn flex justify-between" onClick={toggleDropdown}>
//         <p>{fieldName?.charAt(0).toUpperCase() + fieldName?.slice(1)}</p>{" "}
//         <p>🡃</p>
//       </button>
//       {isOpen && (
//         <div className="dropdown-content">
//           {optionsList?.map((option) => {
//             return (
//               <div
//                 key={option?._id || option?.id || option}
//                 className="dropdown-item"
//                 style={{ backgroundColor: "#E3F2FD", color: "black" }}
//               >
//                 <label>
//                   <input
//                     type="checkbox"
//                     value={
//                       option?._id ||
//                       option?.value ||
//                       option?.diificultyLevel ||
//                       option
//                     }
//                     checked={formData[fieldName]?.includes(
//                       option?._id ||
//                         option?.value ||
//                         option?.diificultyLevel ||
//                         option
//                     )}
//                     onChange={(e) => handleChange(e)}
//                   />
//                   {getOptionDisplayName(option)}
//                 </label>
//               </div>
//             );
//           })}
//         </div>
//       )}
//     </div>
//   );
// };

import React, { useState, useEffect, useRef } from "react";
import "./drop.css";

export const DropDown = ({ optionsList, fieldName, formData, setFormData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event) => {
    const { value, checked } = event.target;
    console.log("value---------", event);
    if (value === "All") {
      if (checked) {
        const allValues = optionsList.map(
          (option) => option._id || option.value
        );
        setFormData({
          ...formData,
          [fieldName]: allValues,
        });
      } else {
        setFormData({
          ...formData,
          [fieldName]: [],
        });
      }
    } else {
      const updatedValues = checked
        ? [...formData[fieldName], value]
        : formData[fieldName].filter((item) => item !== value);

      setFormData({
        ...formData,
        [fieldName]: updatedValues,
      });
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getOptionDisplayName = (option) => {
    if (option?.subjectName) return option.subjectName;
    if (option?.chapterName) return option.chapterName;
    if (option?.topicName) return option.topicName;
    if (option?.subTopicName) return option.subTopicName;
    if (option?.diificultyLevel) return option.diificultyLevel;
    if (option?.papers) return option.papers;
    return option?.name || option?._id || option?.value || option;
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropbtn flex justify-between" onClick={toggleDropdown}>
        <p>{fieldName?.charAt(0).toUpperCase() + fieldName?.slice(1)}</p>{" "}
        <p>🡃</p>
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {optionsList?.map((option) => {
            return (
              <div
                key={option?._id || option?.id || option}
                className="dropdown-item"
                style={{ backgroundColor: "#E3F2FD", color: "black" }}
              >
                <label>
                  <input
                    type="checkbox"
                    value={
                      option?._id ||
                      option?.value ||
                      option?.diificultyLevel ||
                      option
                    }
                    checked={formData[fieldName]?.includes(
                      option?._id ||
                        option?.value ||
                        option?.diificultyLevel ||
                        option
                    )}
                    onChange={(e) => handleChange(e)}
                  />
                  {getOptionDisplayName(option)}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
