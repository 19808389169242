import React from "react";
import "../styles/blogs.css";

const data = [
  {
    title: "A New Beginning",
    description: "Welcome to our blog! This inaugural post marks the beginning of an exciting journey. Join us as we share insightful thoughts, engaging stories, and valuable information with you. Get ready to explore various topics, gain new perspectives, and delve into the realms of knowledge!",
    date: "December 30, 2023"
  },
  {
    title: "Exploring Exciting Ideas",
    description: "Dive into our latest blog entry, where we venture into innovative concepts and explore fascinating ideas. This post is a gateway to new insights, unique perspectives, and thought-provoking discussions. Join us as we navigate through captivating themes and uncover inspiring thoughts!",
    date: "December 30, 2023"
  },
  {
    title: "Unveiling Insights",
    description: "Discover a treasure trove of valuable insights in our newly published blog post. We meticulously uncover hidden gems of knowledge, offering you a deeper understanding of various subjects. Join us as we illuminate complex topics, providing clarity and understanding along the way!",
    date: "December 30, 2023"
  },
  {
    title: "Engaging Content Ahead",
    description: "Prepare yourself for an enriching experience with our recently released blog post. Engage with captivating content that stimulates the mind and ignites curiosity. Join us on this journey as we share compelling stories, thought-provoking discussions, and captivating narratives!",
    date: "December 30, 2023"
  },
  {
    title: "Journey into Knowledge",
    description: "Embark on an enlightening journey of knowledge with our latest blog post. Explore a wide array of topics, from the profound to the practical. Join us as we unravel mysteries, provide expert insights, and offer you a platform to expand your understanding of the world!",
    date: "December 30, 2023"
  }
];

function Blogs() {
  return (
    <>
      <div className="allblogs">
    <h1 className="myblogs">Our Blogs</h1>
     <div className="recent">
     {data.map((elem,i) => {
       return (
        <div key={i} className="blog_item">

       <div  className="blog" > 
         <div className="date">
              {elem.date}
             </div>
        <h1>{elem.title}</h1>
        <div className="blog_desc">{(elem.description).substring(0,200)+ '...'}</div>
      
             <a>
              Read More
             </a>
       </div>
        </div>
       )})}
     </div>
     </div>
    </>
  );
}

export default Blogs;
