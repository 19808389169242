import React from 'react'
import img from "../assets/images/cover.png"

function CoverBackground() {
  return (
        <img src={img} alt="cover image"  className='cover_img'/>
  )
}

export default CoverBackground
