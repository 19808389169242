import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/PrivacyPolicy.css";
function SdP() {
  return (
    <>
      <div className="main_div">
        <h1 className="privacy_heading">Shipping & Delivery Policy</h1>
        <p className="paragraph">
          Last updated on Nov 20th 2023
          <br />
          For International buyers, orders are shipped and delivered through
          registered international courier companies and/or International speed
          post only. For domestic buyers, orders are shipped through registered
          domestic courier companies and /or speed post only. Orders are shipped
          within 8+ days or as per the delivery date agreed at the time of order
          confirmation and delivering of the shipment subject to Courier Company
          / post office norms. STUTEACH INTELLIEDTECH PRIVATE LIMITED is not
          liable for any delay in delivery by the courier company / postal
          authorities and only guarantees to hand over the consignment to the
          courier company or postal authorities within 8+ days from the date of
          the order and payment or as per the delivery date agreed at the time
          of order confirmation. Delivery of all orders will be to the address
          provided by the buyer. Delivery of our services will be confirmed on
          your mail ID as specified during registration. For any issues in
          utilizing our services you may contact our helpdesk on or
          support@intelliedtech.com
        </p>
       </div>
    </>
  );
}

export default SdP;
