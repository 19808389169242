const gradeData = [
    {
        "value": "6",
        "label": "6th Grade"
    },
    {
        "value": "7",
        "label": "7th Grade"
    },
    {
        "value": "8",
        "label": "8th Grade"
    },
    {
        "value": "9",
        "label": "9th Grade"
    },
    {
        "value": "10",
        "label": "10th Grade"
    },
    {
        "value": "11",
        "label": "11th Grade"
    },
    {
        "value": "12",
        "label": "12th Grade"
    }
];
const curriculams = [
    {
        "value": "JEE",
        "label": "JEE"
    },
    {
        "value": "NEET",
        "label": "NEET"
    },
    {
        "value": "IBDP",
        "label": "IBDP"
    },
    {
        "value": "MYP",
        "label": "MYP"
    },
    {
        "value": "IGCSE",
        "label": "IGCSE"
    },
    {
        "value": "SAT",
        "label": "SAT"
    },
];
const subjectsData = [
    {
        "value": "Maths",
        "label": "Mathematics"
    },
    {
        "value": "english",
        "label": "English"
    },
    {
        "value": "Science",
        "label": "Science"
    },
    {
        "value": "History",
        "label": "History"
    },
    {
        "value": "geography",
        "label": "Geography"
    },
    {
        "value": "computer_science",
        "label": "Computer Science"
    },
    {
        "value": "art",
        "label": "Art"
    },
    {
        "value": "music",
        "label": "Music"
    },
    {
        "value": "physical_education",
        "label": "Physical Education"
    },
    {
        "value": "foreign_language",
        "label": "Foreign Language"
    }
];
const languageData = [
    {
        "value": "Hindi",
        "label": "Hindi"
    },
    {
        "value": "English",
        "label": "English"
    },
    {
        "value": "Marathi",
        "label": "Marathi"
    },
    {
        "value": "Urdu",
        "label": "Urdu"
    }
];
const boardOptions = [
    {
        "value": "CBSE",
        "label": "CBSE"
    },
    {
        "value": "ICSE",
        "label": "ICSE"
    },
  
];
const examsData = [
    // { image: "https://th.bing.com/th/id/OIP.MBWutkCESdFhwWdQuYfH9QHaHa?w=167&h=180&c=7&r=0&o=5&pid=1.7", name: "UPSC Civil Services", price: "500" },
    { image: "https://wallpapercave.com/wp/wp1893186.jpg", marks: "200", time:"90", name: "JEE Physics", price: "500" , des : "The Physics section encompasses a wide range of topics, including Mechanics, Electromagnetism, Thermodynamics, Optics, and Modern Physics. Candidates are expected to have a thorough understanding of each area to excel in this section." },
    { image: "https://th.bing.com/th/id/OIP.cvsR1OSoP4htXg6FFugJqgHaE7?rs=1&pid=ImgDetMain",marks: "200", time:"90", name: "NEET Chemistry", price: "500" , des : " The Chemistry section encompasses three main branches: Organic Chemistry, Inorganic Chemistry, and Physical Chemistry. Candidates are expected to have a comprehensive understanding of each branch to excel in this section." },
    // { image: "https://th.bing.com/th/id/OIP.Pweq6NsfOvTgw4oyXR8NCQHaGW?w=205&h=180&c=7&r=0&o=5&pid=1.7", name: "JEE Maths", price: "500" , des : "The Mathematics section covers a wide array of topics, including Algebra, Calculus, Trigonometry, Coordinate Geometry, and Statistics. Candidates are expected to have a comprehensive understanding of each topic to navigate the diverse range of questions." },
    { image: "https://wallpapercave.com/wp/wp1893186.jpg",marks: "200", time:"90", name: "JEE Advance Physics", price: "500" , des : "The Physics section encompasses a wide range of topics, including Mechanics, Electromagnetism, Thermodynamics, Optics, and Modern Physics. Candidates are expected to have a thorough understanding of each area to excel in this section." },
    // { image: "https://www.livelaw.in/h-upload/2020/09/28/1600x960_382078-upsc-civil-service-exam-extra-chance.jpg", name: "UPSC Civil Services", price: "500" },
    { image: "https://th.bing.com/th?id=OIP.l1dWgKycj_NyyNeLaODSGgHaEK&w=333&h=187&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2",marks: "200", time:"90", name: "Board CBSE 10th Biology", price: "500" , des : "The Biology section covers core biological sciences, including Botany and Zoology. Candidates are expected to have a comprehensive understanding of fundamental concepts in these areas." },
    // { image: "https://th.bing.com/th/id/OIP.Cx-iU815Y-WDx8tZMmrecAHaFj?w=222&h=180&c=7&r=0&o=5&pid=1.7", name: "UPSC Civil Services", price: "500" },
  ];


export { gradeData , curriculams,subjectsData,languageData,boardOptions,examsData };
