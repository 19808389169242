import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../Navbar';
import Footer from '../Footer';
import "./Chapter.css";
import { useNavigate } from 'react-router-dom';
import loader from "../../assets/loader/loader.gif";

import { toast } from "react-toastify";

const API_BASE_URL = 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/content-pannel/v1';

const Chapters = () => {
  const location = useLocation();
  const { state } = location;
  const [chapters, setChapters] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedExamId, setSelectedExamId] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState('');
  const [topics, setTopics] = useState([]);
  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [subTopics, setSubTopics] = useState([]);
  const [selectedSubTopicId, setSelectedSubTopicId] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (state) {
      const { exam, subject } = state;
      setExams(exam);
      setSubjects(subject);
    }
  }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Ensure that subjects._id is a string
        const subjectIdString = String(subjects._id);
        
        const response = await axios.get(`${API_BASE_URL}/chapter/getChapters/${subjectIdString}`);
        setChapters(response.data.data);
      } catch (error) {
        console.error('Error fetching chapters:', error);
        // Handle error as needed
      }
    };

    fetchData();
   
  }, [subjects]);
  
  useEffect(() => {
    fetchTopics();
    fetchSubTopics();
  }, []);

  const fetchTopics = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/topic/getTopics`);
      setTopics(response.data.data);
      setLoading(false); // Set loading to false when data is received

    } catch (error) {
      console.error('Error fetching topics:', error);
      setLoading(false); // Set loading to false when data is received
      toast.error('Failed to fetch Topics. Please try again.');

    }
  };
  const fetchSubTopics = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/subTopic`);
      setSubTopics(response.data.data);
      console.log(response.data.data)
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

const handleQuestinList = (exam, subject, topic)=>{
    navigate(`/questions`, {
        state: {
            exam,
            subject,
            topic,
         
        },
      });
}


  return (
    <>
      <div className='chatper_container'>
        <div>
          <h2 style={{ marginTop: '20px', fontSize: '38px', fontWeight: 'bold', textAlign: 'center' }}>Practice Exam Style Questions on {exams.examName} {subjects.subjectName}</h2>
          <p className="landing_page_resource_hub_slider_container_hed_2">Thousands of exam style questions,<br /> filtered by Subject, topic, sub-topic & difficulty level. Detailed and easy to understand with mark-schemes </p>
        </div>
        {loading && <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <img style={{ height: '70px' }} src={loader} alt="loading" />
        </div>}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {chapters.map((chapter, index) => (
            <div key={chapter._id}>
              <h4 style={{fontSize:'14px', fontWeight: 'lighter'}}>Chapter {index + 1}</h4>
              <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>{chapter.chapterName}</h2>
              <div className="topic-list">
              <div className="exam-cards-container">

                {topics
                  .filter((topic) => topic.chapter === chapter._id)
                  .map((topic) => (
                    <div onClick={()=>handleQuestinList(exams, subjects, topic)} key={topic._id} className="chapter-card">
                      <p style={{fontSize:'16px', fontFamily:"Poppins", textAlign:'center', fontWeight:'bold' }}>{topic.topicName}</p>
                      <p style={{fontSize:'14px', fontFamily:"Poppins" , textAlign:'center' }}>{topic.topicDescription}</p>

                      {/* {subTopics
                            .filter((subtopic) => subtopic.topic == topic._id)
                            .map((subtopic) => (
                                <button
                                className='newexambtn'
                                key={subtopic._id}
                                onClick={() => handleSubSubjectClick(topic, subtopic)}
                                >
                                {subtopic.subTopicName}
                                </button>
                            ))} */}

                    </div>
                  ))}
                  </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Chapters;
