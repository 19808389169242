import React from 'react'
import CoverBackground from '../components/CoverBackground'
import "../styles/service.css"
import OnlineTutoringComp from '../components/OnlineTutoringComp'
import InstructionVideo from '../components/InstructionVideo'

function OnlineTutoring() {
  return (
    <div className='main_container'>
   <CoverBackground />
   <div className='main_container_part'>
    <OnlineTutoringComp />
    <InstructionVideo />

    </div>
    </div>
  )
}

export default OnlineTutoring
