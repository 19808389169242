import React, { useEffect, useState } from 'react'
import "../styles/serviceComp.css"

import Select from "react-select";
import { gradeData, languageData, subjectsData } from '../utils/data';

import loader from "../assets/loader/loader.gif";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import featuresCards from '../utils/cards';

function OnlineTutoringComp() {
  const [name, setName] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [userClass, setUserClass] = useState(null);
  const [targetExam, setTargetExam] = useState(null);
  const [priceRange, setPriceRange] = useState(null);

  const [loading,setLoading]=useState(false)
  const navigate=useNavigate()

  const getWidthOfDropdown = () => {
    if(window.innerWidth > 600){
      return  "242px";
    }else{
return  "80vw";
    } 
  };

  
  useEffect(() => {

    window.addEventListener('resize', getWidthOfDropdown);

    return () => {
      window.removeEventListener('resize', getWidthOfDropdown);
    };
  }, []);

 
  const BaseUrl = 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/user-mgmt';
  
  const submitHandler = async () => {
    setLoading(true); // Set loading to true when starting the API call

    // Validation checks for each field
    if (!name || !mobileNumber || !mobileNumber || !selectedSubject || !selectedLanguage || !userClass || !targetExam || !priceRange) {
      setLoading(false); // Set loading to false if validation fails
      toast.error('All fields are mandatory. Please fill in all the details.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return; // Stop execution if any field is empty
    }

    try {
      // Assuming you have an API endpoint to post the tutoring information
      const response = await axios.post(`${BaseUrl}/ums/apis/v1/users/private-tuition`, {
        name: name,
        mobile: mobileNumber,
        email: email,
        subject:  selectedSubject,
        languages : selectedLanguage,
        classes: userClass,
        exams: targetExam,
        priceLimit: priceRange,
      });

      // Handle the response as needed
      console.log('API Response:', response.data);
      // Show toast on successful submission
      toast.success('Your request is submitted successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      navigate("/");

      // You can also navigate or perform other actions after successful submission
    } catch (error) {
      // Handle error, e.g., show an error message to the user
      toast.error('Error submitting your request. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading to false when the API call is complete
    }
  };

  return (
    <div id="OnlineTutoring" className='service_comp_mainContainer'>
      <h1 style={{color : featuresCards[1].color}}>Online Private Tutoring</h1>

      <div className='service_comp_mainContainer_inp_div'>
<label>Name</label>
      <input
            className="service_comp_mainContainer_inp"
            value={name}
            type='text'
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Your Name"
            width={30}
          />
      </div>

      <div className='service_comp_mainContainer_inp_div'>
<label>Mobile Number</label>
      <input
            className="service_comp_mainContainer_inp"
            value={mobileNumber}
            type='number'
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter Your Mobile Number"
            width={30}
          />
          </div>

          <div className='service_comp_mainContainer_inp_div'>
<label>Target Exam</label>

<input
            className="service_comp_mainContainer_inp"
            value={targetExam}
            onChange={(e) => setTargetExam(e.target.value)}
            placeholder="Enter Your Target Exam "
            width={30}
          />
          </div>
          

          <div className='service_comp_mainContainer_inp_div'>
<label>Subject</label>
           <Select
            className="service_comp_mainContainer_dropdown"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                    width: "max-content",
                   minWidth: "100%",
                   borderRadius:"10px",
                   border:"1px solid #032539 ",
                borderColor: "white",
                fontSize:"15px",
                fontFamily:"Poppins",
                height: "48px",
                cursor: "pointer",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#6B4EFF",
              }),
            }}
            placeholder="Select Subject"
            options={subjectsData}
            onChange={(selectedOption) =>
              setSelectedSubject(selectedOption.value)
            }
          />
          </div>

          <div className='service_comp_mainContainer_inp_div'>
<label>Email</label>

            <input
            className="service_comp_mainContainer_inp"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Your Email"
            width={30}
          />
        </div>

        <div className='service_comp_mainContainer_inp_div'>
<label>Language</label>
        <Select
            className="service_comp_mainContainer_dropdown"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                    width: "max-content",
                   minWidth: "100%",
                borderColor: "white",
                fontSize:"15px",
                fontFamily:"Poppins",
                height: "48px",
                borderRadius:"10px",
                border:"1px solid #032539 ",
                cursor: "pointer",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#6B4EFF",
              }),
            }}
            placeholder="Select Language"
            options={languageData}
            onChange={(selectedOption) =>
              setSelectedLanguage(selectedOption.value)
            }
          />
          </div>

          <div className='service_comp_mainContainer_inp_div'>
<label>Class</label>
               <input
            className="service_comp_mainContainer_inp"
            value={userClass}
            type='text'
            onChange={(e) => setUserClass(e.target.value)}
            placeholder="Enter Your Class "
            width={30}
          />
       </div>

       <div className='service_comp_mainContainer_inp_div'>
<label>Price Range</label>

             <input
            className="service_comp_mainContainer_inp"
            value={priceRange}
            type='text'
            onChange={(e) => setPriceRange(e.target.value)}
            placeholder="Enter Your Price Range "
            width={30}
          />
        
</div>

        {loading===true ?
                <img className='service_comp_mainContainer_loader' src={loader} />
      :
          <button 
          style={{backgroundColor : featuresCards[1].color}}
            className="service_comp_mainContainer_btn"
            onClick={submitHandler}
          >
          Connect Now
          </button>
        }

    </div>
  )
}

export default OnlineTutoringComp
