import React, { useState } from "react";
import Modal from "react-responsive-modal";
import "../styles/hero.css";
import { toast } from "react-toastify";
import axios from "axios";

function LoginModal({ showModal, setShowModal }) {
  const [phone, setphone] = useState("");
  const [otp, setOtp] = useState("");
  const [showLogin, setShowLoginModal] = useState(true);
  const baseURL =
    "https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/user-mgmt";
  const handleLogin = async (e) => {
    if (phone === "" || phone.length < 10) {
      toast.error("Enter a valid mobile number !", {
        position: "top-right",
      });
      return;
    }
    try {
      const res = await axios.post(
        `${baseURL}/ums/apis/v1/users/send_otp/sms`,
        {
          phone: phone,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "studentApp",
            app_version_code: "101",
          },
        }
      );

      if (res.status === 200) {
        setShowLoginModal(false);
      }
    } catch (err) {
      toast.error("something went wrong !", {
        position: "top-right",
      });
      console.log(err);
    }
  };
  const handleOTPSubmit = async (e) => {
    try {
      const res = await axios.post(
        `${baseURL}/ums/apis/v1/users/verify_otp`,
        {
          phone: phone,
          otp: otp,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "studentApp",
            app_version_code: "101",
          },
        }
      );
      let expirationDate = new Date();
      expirationDate.setHours(expirationDate.getHours() + 10);
      localStorage.setItem("accessToken", res.data.data.accessToken);
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("tokenExpiresIn", `${expirationDate}`);
      setShowModal(false);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong !", {
        position: "top-right",
      });
    }
  };
  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      center
      styles={{
        modal: { width: "90%", height: "auto", borderRadius: "10px" },
      }}
    >
      <div className="modal_div">
        {showLogin ? (
          <>
            <h1>Login </h1>
            <input
              className="modal_div_inp"
              value={phone}
              onChange={(e) => {
                setphone(e.target.value);
              }}
              type="number"
              placeholder="Enter Your Phone Number"
            />
            <button
              onClick={() => {
                handleLogin();
              }}
            >
              Send OTP
            </button>
          </>
        ) : (
          <>
            <h1>Verify OTP </h1>
            <input
              className="modal_div_inp"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              type="number"
              placeholder="Enter OTP Here"
            />
            <button
              onClick={() => {
                handleOTPSubmit();
              }}
            >
              Verify OTP
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default LoginModal;
