import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import "./Chapter.css";
import "../Resource/Chapter.css";
import { useNavigate } from "react-router-dom";
import loader from "../../assets/loader/loader.gif";
import "react-responsive-modal/styles.css";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import {
  exam,
  garde,
  level,
  pagination,
  paper,
  questionsTypeArray,
  subTopic,
  subject,
  topic,
  unit,
} from "../StaticArray/staticArray";
import calculator from "../../assets/icons/calculator.png";
import calculatorOn from "../../assets/icons/calculator1.png";
import LabelDragReview from "../Resource//newQuestionBox/labelDrag";
import HotSpotReview from "../Resource//newQuestionBox/hotSpot";
import LabelFillReview from "../Resource//newQuestionBox/labelFill";
import GraphReview from "../Resource//newQuestionBox/graph";
import Objective from "../Resource//newQuestionBox/objective";
import Drawing from "../Resource//newQuestionBox/drawing";
import SliderView from "../Resource//newQuestionBox/slider";
import TrueFalse from "../Resource//newQuestionBox/trueFalse";
import { Paragraph } from "../Resource//newQuestionBox/paragraph";
import Classify from "../Resource//newQuestionBox/classify";
import { Sort } from "../Resource//newQuestionBox/Sort";
import { Subjective } from "../Resource//newQuestionBox/subjective";
import { Attach } from "../Resource//newQuestionBox/Attach";
import { Match } from "../Resource//newQuestionBox/Match";
import config from "../../utils/config";
import { DropDown } from "../DropDownPicker/DropDown";
import Lottie from "lottie-react";
import animationData from "../../assets/loader.json";

export const QuestionBank = () => {
  const [filterData, setFilterData] = useState([]);
  const [showNotAvailableModal, setShowNotAvailableModal] = useState(false);
  const [examsList, setExamsList] = useState([]);
  const [gardeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [years, setYears] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [subTopicList, setSubTopicList] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [currentPage, setCurrentPage] = useState("1");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    exam: "",
    grade: "",
    subject: [],
    unit: [],
    topic: [],
    subTopic: [],
    level: [],
    questionType: "",
    paper: [],
    year: [],
  });

  const handleCloseNotAvailableModal = () => {
    setShowNotAvailableModal(false);
  };

  const handleNotAvailable = () => {
    setShowNotAvailableModal(true);
  };

  useEffect(() => {
    fetchExams();
  }, []);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 2000;
    const yearsArray = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get(`${config?.Url}/v1/exam/getExams`);
      setExamsList(response.data.data);
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const fetchGrades = async () => {
    try {
      const response = await axios.get(
        `${config?.Url}/v1/grade/getgrades/${formData?.exam}`
      );
      setGradeList(response.data.data);
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await axios.get(
        `${config?.Url}/v1/subject/getSubjects/${formData?.grade}`
      );
      setSubjectList(response.data.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const fetchChapters = async () => {
    try {
      const response = await axios.get(
        `${config?.Url}/v1/chapter/getChapters/${formData?.subject}`
      );
      setUnitList(response.data.data);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  const fetchTopics = async () => {
    try {
      const response = await axios.get(
        `${config?.Url}/v1/topic/getTopics/${formData?.unit}`
      );
      setTopicList(response.data.data);
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  const fetchSubTopics = async () => {
    try {
      const response = await axios.get(
        `${config?.Url}/v1/subTopic/getSubTopics/${formData?.topic}`
      );
      setSubTopicList(response.data.data);
    } catch (error) {
      console.error("Error fetching subtopics:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    const filteredData = Object.fromEntries(
      Object.entries(newFormData).filter(([key, val]) => val !== "")
    );
    setFilterParams(filteredData);
  };

  const fetchData = async (page) => {
    setLoading(true);
    let data = JSON.stringify({
      exam: formData?.exam ? [formData?.exam] : [],
      subject: formData?.subject,
      chapter: formData?.unit,
      topic: formData?.topic,
      subTopic: formData?.subTopic,
      grade: formData?.grade ? [formData?.grade] : [],
      level: formData?.level,
      year: formData?.year,
      questionType: formData?.questionType ? [formData?.questionType] : [],
      paper: formData?.paper,
      page: page,
    });
    console.log("1789----", data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/content-pannel/v1/report/get/all/que/for/revision/village",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // if (response?.status === 200) {
        let data = response?.data?.data;
        setFilterData(data);
        setLoading(false);
        // }
      })
      .catch((error) => {
        console.log("e----------", error);
        toast.warn("No data found on the basis of this filter!");
        setFilterData();

        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, formData]);

  useEffect(() => {
    fetchGrades();
  }, [formData?.exam]);

  useEffect(() => {
    fetchSubjects();
  }, [formData?.grade]);

  useEffect(() => {
    fetchChapters();
  }, [formData?.subject]);

  useEffect(() => {
    fetchTopics();
  }, [formData?.unit]);

  useEffect(() => {
    fetchSubTopics();
  }, [formData?.topic]);

  const button = (
    <>
      <button
        style={{
          padding: "8px 5px",
          fontSize: "14px",
          borderRadius: "10px",
          fontWeight: "400",
          marginTop: "10px",
          backgroundColor: "#D194CD",
          color: "#000",
        }}
      >
        Connect Instant Teacher
      </button>
      <button
        onClick={handleNotAvailable}
        style={{
          padding: "8px 5px",
          fontSize: "14px",
          borderRadius: "10px",
          fontWeight: "400",
          marginTop: "10px",
          backgroundColor: "#8AADE9",
          color: "#000",
        }}
      >
        Check With IntelliAI
      </button>
    </>
  );

  return (
    <>
      <div className="chatper_container">
        <Modal
          open={showNotAvailableModal}
          onClose={handleCloseNotAvailableModal}
          center
          styles={{
            modal: { width: "40%", height: "35%", borderRadius: "10px" },
          }}
        >
          <div style={{ justifyContent: "center", alignContent: "center" }}>
            <h2
              style={{
                fontSize: "28px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Coming Soon{" "}
            </h2>
            <p style={{ fontSize: "16px", textAlign: "center" }}>
              We are working hard to get this up and running
            </p>
            <div
              className="progress-bar progress-bar-info"
              role="progressbar"
              aria-valuenow="80"
              aria-valuemin="70"
              aria-valuemax="100"
              style={{
                width: "80%",
                borderRadius: "5px 0px 0px 5px",
                margin: "10px",
              }}
            >
              Coming Soon....
            </div>
          </div>
        </Modal>
        <div className="row-data">
          <p className="all-text">All Questions</p>
          <div className="row-data">
            <div className="dropdown">
              <select
                className="dropbtn"
                name="exam"
                value={formData?.exam}
                onChange={handleChange}
              >
                <option value="">Exam</option>
                {Array.isArray(examsList) ? (
                  examsList.map((exam) => (
                    <option
                      key={exam._id}
                      value={exam._id}
                      style={{ backgroundColor: "#E3F2FD", color: "black" }}
                    >
                      {exam.examName}
                    </option>
                  ))
                ) : (
                  <option key={examsList._id} value={examsList._id}>
                    {examsList.examName}
                  </option>
                )}
              </select>
            </div>
            <div className="dropdown">
              <select
                className="dropbtn"
                name="grade"
                value={formData?.grade}
                onChange={handleChange}
              >
                <option value="">Grade</option>
                {Array.isArray(gardeList) ? (
                  gardeList.map((grade) => (
                    <option
                      key={grade._id}
                      value={grade._id}
                      style={{ backgroundColor: "#E3F2FD", color: "black" }}
                    >
                      {grade.gradeName}
                    </option>
                  ))
                ) : (
                  <option key={gardeList._id} value={gardeList._id}>
                    {gardeList.gradeName}
                  </option>
                )}
              </select>
            </div>
            <DropDown
              optionsList={years}
              fieldName="year"
              formData={formData}
              setFormData={setFormData}
            />
            <DropDown
              optionsList={subjectList || []}
              fieldName="subject"
              formData={formData}
              setFormData={setFormData}
            />
            <DropDown
              optionsList={unitList || []}
              fieldName="unit"
              formData={formData}
              setFormData={setFormData}
            />
            <DropDown
              optionsList={topicList || []}
              fieldName="topic"
              formData={formData}
              setFormData={setFormData}
            />
            <DropDown
              optionsList={subTopicList || []}
              fieldName="subTopic"
              formData={formData}
              setFormData={setFormData}
            />
            <DropDown
              optionsList={level}
              fieldName="level"
              formData={formData}
              setFormData={setFormData}
            />
            <DropDown
              optionsList={paper}
              fieldName="paper"
              formData={formData}
              setFormData={setFormData}
            />

            {/* <div className="dropdown">
              <select
                className="dropbtn"
                name="year"
                value={formData?.year}
                onChange={handleChange}
              >
                <option value="">Year</option>
                {Array.isArray(gardeList) ? (
                  years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))
                ) : (
                  <option key={years} value={years}>
                    {years}
                  </option>
                )}
              </select>
            </div> */}
            {/* <div className="dropdown">
              <select
                className="dropbtn"
                name="subject"
                value={formData?.subject}
                onChange={handleChange}
              >
                <option value="">Subject</option>
                {Array.isArray(subjectList) ? (
                  subjectList.map((subject) => (
                    <option key={subject._id} value={subject._id}>
                      {subject.subjectName}
                    </option>
                  ))
                ) : (
                  <option key={subjectList._id} value={subjectList._id}>
                    {subjectList.subjectName}
                  </option>
                )}
              </select>
            </div> */}
            {/* <div className="dropdown">
              <select
                className="dropbtn"
                name="chapter"
                value={formData?.chapter}
                onChange={handleChange}
              >
                <option value="">Unit</option>
                {Array.isArray(unitList) ? (
                  unitList.map((chapter) => (
                    <option key={chapter._id} value={chapter._id}>
                      {chapter.chapterName}
                    </option>
                  ))
                ) : (
                  <option key={unitList._id} value={unitList._id}>
                    {unitList.chapterName}
                  </option>
                )}
              </select>
            </div> */}
            {/* <div className="dropdown">
              <select
                className="dropbtn"
                name="topic"
                value={formData?.topic}
                onChange={handleChange}
              >
                <option value="">Topic</option>
                {Array.isArray(topicList) ? (
                  topicList.map((topic) => (
                    <option key={topic._id} value={topic._id}>
                      {topic.topicName}
                    </option>
                  ))
                ) : (
                  <option key={topicList._id} value={topicList._id}>
                    {topicList.topicName}
                  </option>
                )}
              </select>
            </div> */}
            {/* <div className="dropdown">
              <select
                className="dropbtn"
                name="subTopic"
                value={formData?.subTopic}
                onChange={handleChange}
              >
                <option value="">Sub Topic</option>
                {Array.isArray(subTopicList) ? (
                  subTopicList.map((subTopic) => (
                    <option key={subTopic._id} value={subTopic._id}>
                      {subTopic.subTopicName}
                    </option>
                  ))
                ) : (
                  <option key={subTopicList._id} value={subTopicList._id}>
                    {subTopicList.subTopicName}
                  </option>
                )}
              </select>
            </div> */}
            {/* <div className="dropdown">
              <select
                className="dropbtn"
                name="level"
                value={formData?.level}
                onChange={handleChange}
              >
                <option value="">Level</option>
                {level?.map((item) => {
                  return (
                    <option
                      style={{ backgroundColor: "#E3F2FD", color: "black" }}
                      key={item.id}
                      value={item.diificultyLevel}
                    >
                      {item?.diificultyLevel}
                    </option>
                  );
                })}
              </select>
            </div> */}
            {/* <div className="dropdown">
              <select
                className="dropbtn"
                name="paper"
                value={formData?.paper}
                onChange={handleChange}
              >
                <option value="">Paper</option>
                {paper?.map((item) => {
                  return (
                    <option
                      style={{ backgroundColor: "#E3F2FD", color: "black" }}
                      key={item.id}
                      value={item.paper}
                    >
                      {item.paper}
                    </option>
                  );
                })}
              </select>
            </div> */}
            <div className="dropdown">
              <select
                className="dropbtn"
                name="questionType"
                // value={selectedSubjectId}
                // onChange={(e) => setSelectedSubjectId(e.target.value)}
                value={formData.questionType}
                onChange={handleChange}
              >
                <option value="">Question Type</option>
                {questionsTypeArray?.map((item, index) => {
                  return (
                    <option
                      style={{ backgroundColor: "#E3F2FD", color: "black" }}
                      key={index}
                      value={item.type}
                    >
                      {item.title}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div>Total no of questions : {filterData?.length}</div>
        <div>
          {loading ? (
            <div className="flex justify-center m-60">
              <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
                style={{ height: 50, width: 50 }}
              />
            </div>
          ) : (
            <>
              {filterData?.map((question, index) => {
                return (
                  <div key={index}>
                    {!question?.isDeleted && (
                      <div className="question-card">
                        <div className="w-full flex gap-10">
                          <div className="flex-1">
                            <div className="quetion-row">
                              <div className="text-lg text-gray-800 font-bold">
                                Question : {index + 1}
                              </div>
                              {question?.calculator && (
                                <img
                                  src={calculatorOn}
                                  alt="calc"
                                  className="calc-size"
                                />
                              )}
                              {question?.calculator && (
                                <div className="calc-text">Calculator</div>
                              )}
                            </div>

                            <>
                              {(question?.questionType === "mcq" ||
                                question?.questionType === "mcqmultiple") && (
                                <Objective
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "true/false" && (
                                <TrueFalse
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "slider" && (
                                <SliderView
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "drawing" && (
                                <Drawing
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "graph" && (
                                <GraphReview
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "hotSpot" && (
                                <HotSpotReview
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "labelDrag" && (
                                <LabelDragReview
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "labelFill" && (
                                <LabelFillReview
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "paragraph" && (
                                <Paragraph
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "classify" && (
                                <Classify
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "sort" && (
                                <Sort
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}

                              {(question?.questionType === "subjective" ||
                                question?.questionType === "fillText") && (
                                <Subjective
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "attach" && (
                                <Attach
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                              {question?.questionType === "match" && (
                                <Match
                                  data={question}
                                  button={button}
                                  type="questionBank"
                                />
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="flex items-center justify-between">
          <div></div>

          <div className="flex justify-between items-center mt-14">
            <div className="font-bold text-lg bg-gray-200 p-1 px-3 rounded-lg">
              {"<"}
            </div>

            {pagination?.map((i) => (
              <div
                onClick={() => setCurrentPage(i)}
                className={`m-4 text-lg font-bold ${
                  currentPage === i ? "text-black" : "text-gray-500"
                }`}
              >
                {i}
              </div>
            ))}
            <div className="font-bold text-lg bg-gray-200 p-1 px-3 rounded-lg">
              {">"}
            </div>
          </div>
          <div></div>
        </div>{" "}
      </div>
    </>
  );
};
