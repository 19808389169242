import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/Tnc.css";
function TnC() {
  return (
    <>
      <div className="main_div">
        <h1 className="privacy_heading">Term & Conditions</h1>
        <p className="paragraph">
          Last updated on Nov 20th 2023
          <br />
          <br />
          <br />
          <p>
    1.      Introduction
</p>
<p>
    1.      Stuteach Intelli Edtech  Private  Limited having its registered
    office at C-301, third floor , Nirvana Courtyard  , Gurugram -122018
    (hereinafter referred to as " Intelli", “Intelli Edtech” ,"we", "us", or  "
    our") provides online  courses and conducts online classes, offline classes
    live demo, doubt clearing sessions for the  students seeking to enrol for
    such courses (the " Services"), which Services are accessible at
    www.intelliedtech.com  and any other  websites through which Intelli Edtech
    makes the Services available  (collectively, the "Site ")  and as
    applications for mobile, tablet and other smart devices and application
    program interfaces (collectively, the "Applications").
</p>
<p>
    2.      By accessing or using the Site, Application or Services or by
    downloading or posting any content from or on the Site, via the
    Applications,  you would be indicating that you have read, and that you
    understand and agree  to be bound by these terms and receive our Services (“
    Terms  of Services” or “Terms”), whether or not you have registered with the
    Site and/or  Application.
</p>
<p>
    3.      Therefore, please read these Terms of service before accessing or
    using the Site, Application or Services or downloading or posting any
    content  from or on the Site, via the Application or through the Services,
    carefully as  they contain important information regarding your legal
    rights, remedies and  obligations.
</p>
<p>
    4.      If you do not agree to these Terms, then you have no right to access
    or use the Site, Application, Services, or Collective Content (as defined
    below).
</p>
<p>
    5.      If you are using the Site, Application or Services then these Terms
    of  Service are binding between you and Intelli Edtech.
</p>
<p>
    2.      Definition
</p>
<p>
    In addition to other words and expressions that may  be defined elsewhere in
    these Terms, unless the context otherwise requires, the  following
    capitalized terms wherever used in the Agreement shall have the  meanings as
    ascribed hereunder:
</p>
<p>
    1.      "Courses" means  educational courses listed on the Site or
    Application.
</p>
<p>
    2.      "Course Fees"  means the amounts that are due and payable by a
    Student for enrolment of  Course.
</p>
<p>
    3.      "Collective Content"  means Member Content and Intelli Edtech
    Content.
</p>
<p>
    4.      "Content" means  text, graphics, images, music, software (excluding
    the Application), audio,  video, information or other materials.
</p>
<p>
    5.      "Listing" means  Courses that are listed by Intelli Edtech as
    available via the Site,  Application, and Services.
</p>
<p>
    6.      "Member" means a  person, who completes Intelli Edtech’s account
    registration process,   including but not limited to Teachers and Students,
    as  described under "Account Registration " in Clause 7 below.
</p>
<p>
    7.      "Member Content "  means all Content that a Member posts, uploads,
    publishes, submits, transmits,  or includes in their Listing, Member profile
    or Intelli Edtech promotional  campaign to be made available through the
    Site, Application or Services.
</p>
<p>
    8.      "Payment Method"  means a payment method that you have added to your
    Intelli Edtech Account, such  as a credit card, debit card or net banking.
</p>
<p>
    9.      "Student " means  a Member who enrols for Courses on Application or
    Site, in case of minor  student the parent or guardian who enrol their child
    or ward for the Courses on  Application or Site.
</p>
<p>
    10.  "Tax" or "Taxes " mean any sales taxes, value added  taxes (VAT), goods
    and services taxes (GST), service tax, that Intelli Edtech  may be required
    by law to collect and remit to governmental agencies, and other  similar
    municipal, state, federal and national indirect or other withholding  and
    personal or corporate income taxes.
</p>
<p>
    11.  “Teacher” means a  Member who has been selected by Intelli Edtech in
    order to provide services  through the Site or Application
</p>
<p>
    12.  "Intelli Edtech Content " means all Content that Intelli Edtech makes
    available through  the Site, Application, Services, or its related
    promotional campaigns and  official social media channels, including any
    Content licensed from a third  party, but excluding Student Content.
</p>
<p>
    3.      Terms of Service
</p>
<p>
    1.      We believe that every user of our
    Application/Services/products/Website must be in a position to provide
    informed  consent prior to providing any Information required for the use of
    the  Application/Services/products/Website. By registering with us, you are
    expressly consenting to our collection, processing, storing, disclosing, and
    handling of your information set forth in our Privacy Policy now and as
    amended  by us. Processing, your information in any way, including, but not
    limited to,  collecting, storing, deleting, using, combining, sharing,
    transferring, and  disclosing information, all of which activities will take
    place in India. If  you reside outside India your information will be
    transferred, processed, and  stored in accordance with the applicable data
    protection laws of India.
</p>
<p>
    2.      By using the Site, Applications or Services, you agree to comply
    with  and be legally bound by the terms and conditions of these Terms,
    whether or not  you become a registered user of the Services. These Terms
    govern your access to  and use of the Site, Application and Services and all
    Collective Content  (defined above), and constitute a binding legal
    agreement between you and  Intelli Edtech. The user further accepts to allow
    Intelli Edtech to reach them  through Call, SMS, Email, Whatsapp for
    providing existing services and for  providing information on new products
    and services and it will supersede the  registry of NDNC &amp; DND as per
    the regulation of TRAI under the guidelines  of TCCPR
</p>
<p>
    3.      I hereby authorize to receive SMS, Whatsapp, Email and other
    communications via Intelli Edtech and it supersedes the registry of NDNC
    &amp;  DND as per the regulation of TRAI
</p>
<p>
    2.      In addition, certain areas of the Site and Application (and your
    access to or use of certain aspects of the Services or Collective Content)
    may  have different terms and conditions, standards, guidelines, or policies
    posted  or may require you to agree with and accept additional terms and
    conditions. If  there is a conflict between these Terms and terms and
    conditions posted for a  specific area of the Site, Application, Services,
    or Collective Content, the  latter terms and conditions will take precedence
    with respect to your use of or  access to that area of the Site,
    Application, Services, or Collective Content.
</p>
<p>
    3.      If you do not agree to these Terms, you have no right to obtain
    information from or otherwise continue using the Site, Application or
    Services.  Failure to use the Site, Application or Services in accordance
    with these Terms  may subject you to civil and criminal liabilities.
</p>
<p>
    4.      The Site, Application and Services comprise an online platform which
    creates Listings for Courses and Students may learn about and enrol for the
    course directly through the Site and Application.
</p>
<p>
    5.      You acknowledge and agree that, by accessing or using the Site,
    Application or Services or by downloading or posting any content from or on
    the  Site or via the Application, you are indicating that you have read, and
    that  you understand and agree to be bound by these Terms and receive our
    Services,  whether or not you have registered with the Site and Application.
    If you do not  agree to these terms, then you have no right to access or use
    the Site,  Application, Services, or Collective Content. If you accept or
    agree to these  Terms on behalf of a company or other legal entity, you
    represent and warrant  that you have the authority to bind that company or
    other legal entity to these  Terms and, in such event, "you" and "your" will
    refer and apply  to that company or other legal entity.
</p>
<p>
    4.      GDPR COMPLIANCE STATEMENT
</p>
<p>
    Intelli Edtech respects and complies with the EU  General Data Protection
    Regulations (GDPR). Some of the key ways we comply with  these regulations
    are:
</p>
<p>
    1.      Consent
</p>
<p>
    We explain what you’re consenting to clearly and  without ‘legalese’, and
    ask that you explicitly consent to contact from us.
</p>
<p>
    2.      Breach Notification
</p>
<p>
    In the event of a breach, we will notify affected  users within 72 hours of
    first having become aware of the breach.
</p>
<p>
    3.      Right to Access
</p>
<p>
    Users can request confirmation as to whether or not  personal data
    concerning them is being processed, where and for what purpose.  Further, we
    shall provide a copy of the personal data, in an electronic format.
</p>
<p>
    4.      Right to be Forgotten
</p>
<p>
    Once we have compared your (the subjects') rights  to "the public interest
    in the availability of the data", we may  delete your personal data where
    you have requested this.
</p>
<p>
    5.      Data Portability
</p>
<p>
    We allow you to receive the personal data  concerning you, which we will
    provide in a 'commonly used and machine readable  format' and you have the
    right to transmit that data to another ‘controller’.
</p>
<p>
    6.      Privacy by Design
</p>
<p>
    We implement appropriate technical and  organisational measures, in an
    effective way and protect the rights of data  subjects'. We hold and process
    only the data absolutely necessary for the  completion of our duties (data
    minimisation), as well as limiting the access to  personal data to those
    needing to act out the processing.
</p>
<p>
    5.      Eligibility
</p>
<p>
    Use of the Site, Application and Services is  available only to persons who
    can form legally binding   contracts under Indian laws. The Site,
    Application and Services  are intended solely for persons who are 18 years
    of age or older. If you are  below 18, then your parent or guardian can open
    an account and help you enrol  in courses that are appropriate for you. The
    use of the Site, Application and  Services is also not available to persons
    whose membership has been suspended  or terminated by Intelli Edtech for any
    reason whatsoever. Any access to or use  of the Site, Application or
    Services by anyone under 18 years is expressly  prohibited. By accessing or
    using the Site, Application or Services you  represent and warrant that you
    are 18 years or older.
</p>
<p>
    6.      Usage of Site, Application or Services
</p>
<p>
    1.      The Site, Application and Services can be used to facilitate the
    Students to enrol for Courses. Such Courses are included in Listings on the
    Site, Application and Services by Intelli Edtech. You may view Listings as
    an  unregistered visitor to the Site, Application and Services; however, if
    you  wish to enrol for the Courses, you must first register to create a
    Intelli  Edtech Account (defined below).
</p>
<p>
    2.      Intelli Edtech makes available an online platform which provides
    various online Courses for the Students. Unless explicitly specified
    otherwise  in the Intelli Edtech platform, Intelli Edtech's responsibilities
    are limited  to facilitating the availability of the Courses through the
    Site, Application  and Services.
</p>
<p>
    7.      Account Registration
</p>
<p>
    1.      In order to access certain features of the Site and Application, and
    to enrol for Courses, you must register to create an account ("Intelli
    Edtech Account ") and become a  Member. You may register to join the
    Services directly via the Site or  Application or as described in this
    section. A Teacher is also registered and  an account (“ Intelli Edtech
    Account”) created once selected by Intelli Edtech for providing services
    through the Site or Application.
</p>
<p>
    2.      You can also register to join by logging into your account with
    certain third-party social networking sites ("SNS  ") (including, but not
    limited to, Facebook; each such  account, a "Third-Party Account"), via our
    Site or Application, as  described below. As part of the functionality of
    the Site, Application and  Services, you may link your Intelli Edtech
    Account with Third-Party Accounts,  by either: (i) providing your
    Third-Party Account login information to Intelli  Edtech through the Site,
    Services or Application; or (ii) allowing Intelli  Edtech to access your
    Third-Party Account, as permitted under the applicable terms  and conditions
    that govern your use of each Third-Party Account. You represent  that you
    are entitled to disclose your Third-Party Account login information to
    Intelli Edtech and/or grant Intelli Edtech access to your Third-Party
    Account  (including, but not limited to, for use for the purposes described
    herein),  without breach by you of any of the terms and conditions that
    govern your use  of the applicable Third-Party Account and without
    obligating Intelli Edtech to  pay any fees or making Intelli Edtech subject
    to any usage limitations imposed  by such third-party service providers. By
    granting Intelli Edtech access to any  Third-Party Accounts, you understand
    that Intelli Edtech will access, make  available and store (if applicable)
    any Content that you have provided to and  stored in your Third-Party
    Account (" SNS Content  ") so that it is available on and through the Site,
    Services and Application via your Intelli Edtech Account and Intelli Edtech
    Account profile page. Unless otherwise specified in these Terms, all SNS
    Content, if any, will be considered to be Member Content for all purposes of
    these Terms. Depending on the Third-Party Accounts you choose and subject to
    the privacy settings that you have set in such Third-Party Accounts,
    personally  identifiable information that you post to your Third-Party
    Accounts will be  available on and through your Intelli Edtech Account on
    the Site, Services and  Application. Please note that if a Third-Party
    Account or associated service  becomes unavailable or Intelli Edtech's
    access to such Third-Party Account is  terminated by the third-party service
    provider, then SNS Content will no longer  be available on and through the
    Site, Services and Application. You have the  ability to disable the
    connection between your Intelli Edtech Account and your  Third-Party
    Accounts, at any time, by accessing the "Settings"  section of the Site and
    Application. Please note that your relationship with  the third-party
    service providers associated with your third-party accounts is  governed
    solely by your agreement(s) with such third-party service providers.
    Intelli Edtech makes no effort to review any SNS Content for any purpose,
    including but not limited to for accuracy, legality or non-infringement and
    Intelli Edtech is not responsible for any SNS Content.
</p>
<p>
    3.      Your Intelli Edtech Account and your Intelli Edtech Account profile
    page will be created for your use of the Site and Application based upon the
    personal information you provide to us or that we obtain via an SNS as
    described above. You may not have more than one (1) active Intelli Edtech
    Account. You agree to provide accurate, current and complete information
    during  the registration process and to update such information to keep it
    accurate,  current and complete. Intelli Edtech reserves the right to
    suspend or terminate  your Intelli Edtech Account and your access to the
    Site, Application and  Services if you create more than one (1) Intelli
    Edtech Account, or if any  information provided during the registration
    process or thereafter proves to be  inaccurate, fraudulent, not current,
    incomplete, or otherwise in violation of  these Terms.
</p>
<p>
    4.      You are responsible for safeguarding your password. You agree that
    you  will not disclose your password to any third party and that you will
    take sole  responsibility for any activities or actions under your Intelli
    Edtech Account,  whether or not you have authorized such activities or
    actions. You will  immediately notify Intelli Edtech of any unauthorized use
    of your Intelli  Edtech Account.
</p>
<p>
    8.      Course Listings
</p>
<p>
    1.      Intelli Edtech will create Listing of various online Courses and the
    details about the Course, including, but not limited to, the subject, topic,
    number of sessions, mode of conduct, and time slots of the lectures and
    pricing  and related rules and financial terms will be listed on the Site
    and the  Application. Listings will be made publicly available via the Site,
    Application  and Services. You understand and agree that the placement or
    ranking of  Listings in search results may depend on a variety of factors,
    including, but  not limited to Students preferences, ratings.
</p>
<p>
    2.      Students will be able to view Courses via the Site, Application and
    Services based upon the information provided in the Listing, Students
    requirements, and Students' search parameters and preferences. We understand
    and agree that once a Student requests enrolment for Course, We may not
    request  the Student to pay a higher price than that mentioned in the Site
    or  Application.
</p>
<p>
    3.      Please note that Intelli Edtech assumes no responsibility for a
    Student's compliance with any agreements with or duties to third parties,
    applicable laws, rules and regulations. Intelli Edtech reserves the right,
    at  any time and without prior notice, to remove or disable access to any
    Student  for any reason, that Intelli Edtech, in its sole discretion,
    considers to be  objectionable for any reason, in violation of these Terms
    or Intelli Edtech's  then-current Standards, Trademark &amp; Branding
    Guidelines, or otherwise  harmful to the Site, Application or Services.
</p>
<p>
    4.      If you are a Teacher, you understand and agree that your
    relationship  with Intelli Edtech is limited to being a Member and an
    independent,  third-party contractor, and not an employee, agent,
    joint- venturer or partner of Intelli Edtech for any reason, and you act
    exclusively on your own behalf and for your own benefit, and not on behalf
    of  or for the benefit of Intelli Edtech. Intelli Edtech does not control,
    your  offline activities. As a Member you agree not to do anything to create
    a false  impression that you are endorsed by, partnering with, or acting on
    behalf of or  for the benefit of Intelli Edtech, including by
    inappropriately using any  Intelli Edtech intellectual property.
</p>
<p>
    5.      When you enrol for a Course, you will also be required to upload
    certain information, such as, uploading a profile picture or verifying phone
    number. Any Member wishing to enrol for any of the Courses must meet these
    requirements.
</p>
<p>
    9.      No Endorsement
</p>
<p>
    By using the Site, Application or Services, you  agree that any legal remedy
    or liability that you seek to obtain for actions or  omissions of other
    Members or other third parties will be limited to a claim  against the
    particular Members or other third parties who caused you harm. You  agree
    not to attempt to impose liability on or seek any legal remedy from  Intelli
    Edtech with respect to such actions or omissions.
</p>
<p>
    10.  Payment Terms
</p>
<p>
    1.      If an enrolment is requested for any Course via the Site,
    Application  or Services, We will either pre-approve, confirm or reject the
    enrolment  request within the period of 7 days from the date of request for
    enrolment (‘ Enrolment Request Period’ ), otherwise the  enrolment request
    will automatically expire. If We are unable to confirm or  decide to reject
    an enrolment request within the Enrolment Request Period, any  amounts
    collected by Intelli Edtech for the requested enrolment will be  refunded to
    the concerned Student. When We confirm an enrolment requested by a  Student,
    Intelli Edtech will send the Student an email, text message or message  via
    e-mail and the Application confirming such enrolment, depending on the
    selections you make via the Site, Application and Services.
</p>
<p>
    2.      The Course Fees payable will be displayed to the Student before the
    Student sends an enrolment request to Intelli Edtech. Upon receipt of the
    Students enrolment request, Intelli Edtech may initiate a pre-authorization
    and/or charge a nominal amount to Student’s Payment Method pursuant to the
    Payments Terms. If a requested enrolment is cancelled (before any tuitions
    are  provided), any amounts collected by Intelli Edtech will be refunded to
    such  Student, depending on the selections the Student makes via the Site
    and  Application, and any pre-authorization of Student's Payment Method will
    be  released, if applicable.
</p>
<p>
    3.      Intelli Edtech will collect the Course Fees from Students at the
    time  of the enrolment request.
</p>
<p>
    4.      Intelli Edtech agrees that no refund will be permitted in respect of
    tuitions already provided and  in accordance with the cancellation policy
    reflected in the relevant Listing, (i) permit the Student to cancel the
    enrolment and (ii) refund to the Student that portion of the Course Fees
    specified in the applicable cancellation policy. In case relevant Listing
    does  not have a specified cancellation policy, the refund amount would be
    pro-rata  to the unconsumed portion of the tuition.
</p>
<p>
    5.      You agree that Intelli Edtech through its Site or Application would
    raise system generated invoice to the Student in relation to the Course for
    which the Student has enrolled  or in relation to any kind of payment done,
    as per applicable laws.  Intelli Edtech will raise invoice for the above
    which shall be inclusive of all  applicable Taxes.
</p>
<p>
    6.      You as a Student agree to pay the Course Fees for any enrolment
    requested, in connection with your Intelli Edtech Account. Intelli Edtech
    will  collect the Course Fees pursuant to the Payments Terms.
</p>
<p>
    7.      Once you're confirmed enrolment transaction is complete you  will
    receive a confirmation email summarizing your confirmed enrolment.
</p>
<p>
    11.  Cancellations and Refunds
</p>
<p>
    1.      If, as a Student, you wish to cancel a confirmed enrolment made via
    the Site or the Application, after enrolment to the Course, the cancellation
    policy contained in the applicable Listing will apply to such cancellation
    provided that no refund will be made in respect of tuitions already
    provided.  Our ability to refund the Course Fees and other amounts charged
    to you will  depend upon the terms of the applicable cancellation policy and
    financial  charges applicable in case of course payment through No Cost EMI
    options.  Details regarding refunds and cancellation policies are available
    via the Site  and Application. Intelli Edtech will initiate any refunds due
    pursuant to the  Payments Terms. Please refer to the Refunds section of FAQs
    for latest updated  terms and conditions for various categories of
    listings/courses.
</p>
<p>
    2.      If We cancel a confirmed enrolment made via the Site, Services, and
    Application, (i) Intelli Edtech will refund the Course Fees paid by the
    Student  for such enrolment to the applicable Student pursuant to the
    Payments Terms  which shall not exceed the total amount paid by the Student.
</p>
<p>
    12.  Taxes
</p>
<p>
    You understand and acknowledge that appropriate  governmental agencies,
    departments or authorities (the "Tax Authority ") where your office or
    residence is located may require Taxes to be collected from Students on the
    amount paid for the Course and to be remitted to the respective Tax
    Authority.  The laws in jurisdictions may vary, but these Taxes may be
    required to be  collected and remitted as a percentage of the Course Fees
    set by Intelli  Edtech.
</p>
<p>
    13.  User Conduct
</p>
<p>
    1.      You understand and agree that you are solely responsible for
    compliance with any and all laws, rules, regulations, and Tax obligations
    that  may apply to your use of the Site, Application, Services and
    Collective  Content. In connection with your use of the Site, Application,
    Services and  Collective Content, you may not and you agree that you will
    not:
</p>
<p>
    1.      violate any local, state, national, or other law or regulation, or
    any  order of a court, including, without limitation, Tax regulations;
</p>
<p>
    2.      use manual or automated software, devices, scripts, robots,
    backdoors  or other means or processes to access, "scrape," "crawl" or
    "spider" any web pages or other services contained in the Site,
    Application, Services or Collective Content;
</p>
<p>
    3.      access or use our Site, Application, Services expose or allow to be
    used or exposed, any Intelli Edtech Content: (i) that is not publicly
    displayed  by Intelli Edtech in its search results pages or listing pages
    before an  enrolment is confirmed; (ii) in any way that is inconsistent with
    the Intelli  Edtech’s  Privacy Policy  or Terms of Service;  or (iii) in any
    way that otherwise violates the privacy rights or any other  rights of
    Intelli Edtech's users or any other third party;
</p>
<p>
    4.      use the Site, Application, Services or Collective Content for any
    commercial or other purposes that are not expressly permitted by these Terms
    or  in a manner that falsely implies Intelli Edtech endorsement, partnership
    or  otherwise misleads others as to your affiliation with Intelli Edtech;
</p>
<p>
    5.      dilute, tarnish or otherwise harm the Intelli Edtech brand in any
    way,  including through unauthorized use of Collective Content, registering
    and/or  using Intelli Edtech or derivative terms in domain names, trade
    names,  trademarks or other source identifiers, or registering and/or using
    domains  names, trade names, trademarks or other source identifiers that
    closely imitate  or are confusingly similar to Intelli Edtech domains,
    trademarks, taglines,  promotional campaigns or Collective Content;
</p>
<p>
    6.      copy, store or otherwise access or use any information contained on
    the Site, Application, Services or Collective Content for purposes not
    expressly permitted by these Terms;
</p>
<p>
    7.      infringe the rights of Intelli Edtech or the rights of any other
    person or entity, including without limitation, their intellectual property,
    privacy, publicity or contractual right
</p>
<p>
    8.      interfere with or damage our Site, Application or Services,
    including,  without limitation, through the use of viruses, cancel bots,
    Trojan horses,  harmful code, flood pings, denial-of-service attacks,
    backdoors, packet or IP  spoofing, forged routing or electronic mail address
    information or similar  methods or technology;
</p>
<p>
    9.      use our Site, Application or Services to transmit, distribute, post
    or  submit any information concerning any other person or entity, including
    without  limitation, photographs of others without their permission,
    personal contact  information or credit, debit, calling card or account
    numbers;
</p>
<p>
    10.  use our Site, Application, Services or Collective Content in
    connection with the distribution of unsolicited commercial email ("spam ")
    or advertisements unrelated to  lodging in a private residence;
</p>
<p>
    11.  "stalk" or harass any other user of our Site, Application,  Services or
    Collective Content, or collect or store any personally identifiable
    information about any other user other than for purposes of transacting as
    an  Intelli Edtech Student;
</p>
<p>
    12.  register for more than one Intelli Edtech Account or register for an
    Intelli Edtech Account on behalf of an individual other than yourself,
    except  in case where the parent is registering the account for its child or
    guardian  for its ward;
</p>
<p>
    13.  contact another Member for any purpose other than asking a question
    related to an Enrolment, Course, Listing, or the Member's use of the Site,
    Application and Services;
</p>
<p>
    14.  recruit or otherwise solicit any Member to join third-party services
    or websites that are competitive to Intelli Edtech, without Intelli Edtech's
    prior written approval;
</p>
<p>
    15.  recruit or otherwise solicit any Member to join third-party services,
    applications or websites, without Our prior written approval;
</p>
<p>
    16.  impersonate any person or entity, or falsify or otherwise misrepresent
    yourself or your affiliation with any person or entity;
</p>
<p>
    17.  use automated scripts to collect information from or otherwise
    interact with the Site, Application, Services or Collective Content;
</p>
<p>
    18.  use the Site, Application, Services or Collective Content to find a
    Student and then complete an enrolment of Course independent of the  Site,
    Application or Services, in order to circumvent the obligation to pay any
    Fees related to Intelli Edtech's provision of the Services or for any other
    reasons;
</p>
<p>
    19.  violate these Terms or Intelli Edtech's then-current Policies and or
    Standards;
</p>
<p>
    20.  engage in disruptive, circumventive, abusive or harassing behaviour in
    any area or aspect of our Platform, Application, or Services;
</p>
<p>
    21.  post, upload, publish, submit or transmit any Content that: (i)
    infringes, misappropriates or violates a third party's patent, copyright,
    trademark, trade secret, moral rights or other intellectual property rights,
    or  rights of publicity or privacy; (ii) violates, or encourages any conduct
    that  would violate, any applicable law or regulation or would give rise to
    civil  liability; (iii) is fraudulent, false, misleading (directly or by
    omission or  failure to update information) or deceptive; (iv) is
    defamatory, obscene,  pornographic, vulgar or offensive; (v) promotes
    discrimination, bigotry,  racism, hatred, harassment or harm against any
    individual or group; (vi) is  violent or threatening or promotes violence or
    actions that are threatening to  any other person; or (vii) promotes illegal
    or harmful activities or  substances;
</p>
<p>
    22.  systematically retrieve data or other content from our Site,
    Application or Services to create or compile, directly or indirectly, in
    single  or multiple downloads, a collection, compilation, database,
    directory or the  like, whether by manual methods, through the use of bots,
    crawlers, or spiders,  or otherwise;
</p>
<p>
    23.  use, display, mirror or frame the Site, Application, Services or
    Collective Content, or any individual element within the Site, Application,
    Services or Collective Content, Intelli Edtech's name, any Intelli Edtech
    trademark, logo or other proprietary information, or the layout and design
    of  any page or form contained on a page in the Site, Application or
    Services,  without Intelli Edtech's express written consent;
</p>
<p>
    24.  access, tamper with, or use non-public areas of the Site, Application
    or Services, Intelli Edtech's computer systems, or the technical delivery
    systems of Intelli Edtech's providers;
</p>
<p>
    25.  attempt to probe, scan, or test the vulnerability of any Intelli
    Edtech system or network or breach any security or authentication measures;
</p>
<p>
    26.  avoid, bypass, remove, deactivate, impair, descramble, or otherwise
    circumvent any technological measure implemented by Intelli Edtech or any of
    Intelli Edtech's providers or any other third party (including another user)
    to  protect the Site, Services, Application or Collective Content;
</p>
<p>
    27.  forge any TCP/IP packet header or any part of the header information
    in any email or newsgroup posting, or in any way use the Site, Services,
    Application or Collective Content to send altered, deceptive or false
    source-identifying information;
</p>
<p>
    28.  attempt to decipher, decompile, disassemble or reverse engineer any of
    the software used to provide the Site, Services, Application or Collective
    Content;
</p>
<p>
    29.  advocate, encourage, or assist any third party in doing any of the
    foregoing; or
</p>
<p>
    30.  accept or make a payment for Course Fees outside Intelli Edtech. If
    you do so, you acknowledge and agree that you: (i) would be in breach of
    these  Terms; (ii) accept all risks and responsibility for such payment, and
    (iii)  hold Intelli Edtech harmless from any liability for such payment.
</p>
<p>
    2.      Intelli Edtech has the right to investigate and prosecute violations
    of any of the above to the fullest extent of the law. In addition, and as
    set  in these Terms, Intelli Edtech may take a range of actions against you,
    including but not limited to deactivating or cancelling Intelli Edtech
    Account,  for a violation of this Section or these Terms.
</p>
<p>
    3.      Intelli Edtech may access, preserve and disclose any of your
    information if we are required to do so by law, or if we believe in good
    faith  that it is reasonably necessary to (i) respond to claims asserted
    against  Intelli Edtech or to comply with legal process (for example,
    summons or  warrants), (ii) enforce or administer our agreements with users,
    such as these  Terms, (iii) for fraud prevention, risk assessment,
    investigation, customer  support, product development and debugging
    purposes, or (iv) protect  the rights, property or safety of Intelli Edtech,
    its users, or members of the  public.
</p>
<p>
    4.      You acknowledge that Intelli Edtech has no obligation to monitor
    your  access to or use of the Site, Application, Services or Collective
    Content or to  review or edit any Member Content, but has the right to do so
    for the purpose  of operating and improving the Site, Application and
    Services (including  without limitation for fraud prevention, risk
    assessment, investigation and  customer support purposes), to ensure your
    compliance with these Terms, to  comply with applicable law or the order or
    requirement of a court,  administrative agency or other governmental body,
    to respond to content that it  determines is otherwise objectionable or as
    set forth in these Terms.
</p>
<p>
    5.      Intelli Edtech reserves the right, at any time and without prior
    notice, to remove or disable access to any Collective Content that Intelli
    Edtech, at its sole discretion, considers to be objectionable for any
    reason,  in violation of these Terms or otherwise harmful to the Site,
    Application or  Services.
</p>
<p>
    14.  Child Safety Policy
</p>
<p>
    This policy is applicable to all persons and  organizations associated with
    Intelli Edtech, referred to as Intelli Edtech’s  Stakeholders, including:
</p>
<p>
    1.      Employees of Intelli Edtech, who maybe full-time or part-time
    employees, permanent or temporary employee, regular permanent employee or
    employed on contract
</p>
<p>
    2.      Organizations and persons belonging to that organization, who may
    partner and/ or work with Intelli Edtech in any capacity, even for a limited
    time period or job
</p>
<p>
    3.      Volunteers who may work with Intelli Edtech, in whichever capacity,
    even for a limited period of time
</p>
<p>
    4.      Children enrolled as students of Intelli Edtech and their parents or
    guardians who associate with the child in the official records of Intelli
    Edtech
</p>
<p>
    5.      Children who may not be enrolled but are reached out through
    marketing  or sales of the product and their parents or guardians who
    associate with the  Child
</p>
<p>
    6.      Any other person or organisations who may be officially associated
    with Intelli Edtech and its ambit of work and activity
</p>
<p>
    In instances and circumstances where Intelli Edtech  may not have required
    control over the person alleged of any concern under this  policy, including
    an instance of child abuse, Intelli Edtech shall provide the  required
    cooperation, assistance and support to such parent or guardian of the
    affected child, in approaching any external, legal mechanism including the
    Police and/or the Courts, though Intelli Edtech is itself not personally
    liable  in such instances.
</p>
<p>
    Intelli Edtech expects all its stakeholders falling  within the scope of
    this policy <strong>[Part A (II)]</strong> to adhere to the following  child
    safety code of conduct :
</p>
<p>
    1.      Expected Behaviour and Actions
</p>
<p>
    1.      Listen to the child and, their views and opinions
</p>
<p>
    2.      Have a non-judgmental attitude
</p>
<p>
    3.      Treat every child with empathy and respect regardless of his/her
    race,  colour, gender, sexuality, religion, language, heritage, religious
    belief,  social origin, or any point that discriminated a child
</p>
<p>
    4.      Use appropriate language, behaviours while interacting with the
    child
</p>
<p>
    5.      Use appropriate language, behaviours in any online medium used by
    the  organisation to communicate or engage with children
</p>
<p>
    6.      Create an environment that enables children to share and express
    freely
</p>
<p>
    7.      Always take permission and written consent from guardian before
    taking  photos or videos of a child
</p>
<p>
    8.      Keep all personal information of children, their parents and
    guardians  confidential and secure, such information shall only be shared
    with authorised  individuals
</p>
<p>
    9.      The live online classes and the content, including but not limited
    to  audio visual content is age appropriate and culturally appropriate
</p>
<p>
    2.      Prohibited Behaviours and Actions
</p>
<p>
    1.      Do not develop, induce or support any emotional, online/offline
    physical abuse or sexual relationship with children in any way
</p>
<p>
    2.      Do not use or encourage the use of alcohol, drugs, cigarettes or
    other  intoxicating substance in any of your interaction with children
</p>
<p>
    3.      Do not develop any form of relationship or arrangement with children
    including but not limited to financial, which could be deemed to be
    exploitative or abusive
</p>
<p>
    4.      Do not share with or show children online/offline any inappropriate
    content including pornographic material or material that encourages crime,
    violence, racism, sexism, self-harm, suicide, cruelty
</p>
<p>
    5.      Do not use language or behaviour towards children that is
    inappropriate, harassing, abusive, sexually provocative, demeaning,
    intimidating, discriminatory, or culturally insensitive
</p>
<p>
    15.  Reporting Misconduct
</p>
<p>
    If anyone who you feel is acting or has acted  inappropriately, including
    but not limited to anyone who   (i) engages in offensive, violent or
    sexually inappropriate  behaviour, or (ii) engages in any other disturbing
    conduct, you should  immediately report such person to the appropriate
    authorities and then to  Intelli Edtech by contacting us with your police
    station and report number;  provided that your report will not obligate us
    to take any action beyond that  required by law (if any) or cause us to
    incur any liability to you.
</p>
<p>
    16.  Privacy
</p>
<p>
    In order to provide the services to you we collect  some of your Personal
    Information. We describe our collection and use of  personal information in
    our  Privacy Policy . Please review this policy. You must agree to the
    processing of your  personal information as laid out in Intelli Edtech's
    Privacy  Policy (as may be updated from time to time). You agree that
    Intelli  Edtech may disclose personal information provided to us, including
    the data  entered into the Website, if required to by law or government
    request such as a  warrant, or as specified in the  Privacy Policy .  You
    must be 18 years or older to use this service. You are responsible for
    preventing unauthorized access to your account.
</p>
<p>
    17.  Intellectual Property Ownership and Rights Notices
</p>
<p>
    The Site, Application, Services, and Collective  Content are protected by
    copyright, trademark, and other laws of India. You  acknowledge and agree
    that the Site, Application, Services and Collective  Content, including all
    associated intellectual property rights, are the  exclusive property of
    Intelli Edtech and its licensors. You will not remove,  alter or obscure any
    copyright, trademark, service mark or other proprietary  rights notices
    incorporated in or accompanying the Site, Application, Services,  or
    Collective Content. All trademarks, service marks, logos, trade names, and
    any other proprietary designations of Intelli Edtech used on or in
    connection  with the Site, Application, Services, and Intelli Edtech Content
    are trademarks  or registered trademarks of Intelli Edtech in India and
    abroad. Trademarks,  service marks, logos, trade names and any other
    proprietary designations of  third parties used on or in connection with the
    Site, Application, Services,  and Intelli Edtech Content are used for
    identification purposes only and may be  the property of their respective
    owners. As a Member, you understand and agree  that you are bound by the
    additional Terms, Guidelines and Policies that apply  to your use of the
    Site, Application, Services and Collective Content, including  Intelli
    Edtech's Trademark &amp; Branding Guidelines (as may be updated from  time
    to time).
</p>
<p>
    18.  Additional Terms
</p>
<p>
    Our Site, Application and Services have different  products, features and
    offerings, so sometimes additional terms or product  requirements may apply
    to your use of those products, features or offerings. If  additional terms
    are available for the relevant product or Services you use,  those
    additional terms become part of these Terms.
</p>
<p>
    19.  Application License
</p>
<p>
    Subject to your compliance with these Terms, Intelli  Edtech grants you a
    limited non-exclusive, non-transferable license to download  and install a
    copy of the Application on each mobile device or computer that  you own or
    control and run such copy of the Application solely for your own  personal
    use.
</p>
<p>
    20.  Intelli Edtech Content and Member Content License
</p>
<p>
    1.      Subject to your compliance with these Terms, Intelli Edtech grants
    you  a limited, non-exclusive, non-transferable license, to (a) access and
    view any  Intelli Edtech Content solely for your personal  and
    non-commercial purposes and (b) access and view any Member  Content to which
    you are permitted access, solely for your personal  and non-commercial
    purposes. You have no right to sublicense the  license rights granted in
    this section.
</p>
<p>
    2.      You will not use, copy, adapt, modify, prepare derivative works
    based  upon, distribute, license, sell, transfer, publicly display, publicly
    perform,  transmit, broadcast or otherwise exploit the Site, Application,
    Services, or  Collective Content, except as expressly permitted in these
    Terms. No licenses  or rights are granted to you by implication or otherwise
    under any intellectual  property rights owned or controlled by Intelli
    Edtech or its licensors, except  for the licenses and rights expressly
    granted in these Terms.
</p>
<p>
    21.  Member Content
</p>
<p>
    1.      We may, in our sole discretion, permit you to post, upload, publish,
    submit or transmit Member Content. By making available any Member Content on
    or  through the Site, Application, Services, or through Intelli Edtech
    promotional  campaigns, you hereby grant to Intelli Edtech a worldwide,
    irrevocable,  perpetual (or for the term of the protection), non-exclusive,
    transferable,  royalty-free license, with the right to sublicense, to use,
    view, copy, adapt,  modify, distribute, license, sell, transfer, publicly
    display, publicly  perform, transmit, stream, broadcast, access, view, and
    otherwise exploit such  Member Content on, through, by means of or to
    promote or market the Site,  Application and Services. Intelli Edtech does
    not claim any ownership rights in  any such Member Content and nothing in
    these Terms will be deemed to restrict  any rights that you may have to use
    and exploit any such Member Content.
</p>
<p>
    2.      You acknowledge and agree that you are solely responsible for all
    Member Content that you make available through the Site, Applications,
    Services  or through Intelli Edtech promotional campaigns. Accordingly, you
    represent and  warrant that: (i) you either are the sole and exclusive owner
    of all Member  Content that you make available through the Site,
    Application, Services or  through Intelli Edtech promotional campaigns or
    you have all rights, licenses,  consents and releases that are necessary to
    grant to Intelli Edtech the rights  in such Member Content, as contemplated
    under these Terms; and (ii) neither the  Member Content nor your posting,
    uploading, publication, submission or  transmittal of the Member Content or
    Intelli Edtech's use of the Member Content  (or any portion thereof) on,
    through or by means of the Site, Application, the  Services or Intelli
    Edtech promotional campaigns will infringe, misappropriate  or violate a
    third party's patent, copyright, trademark, trade secret, moral  rights or
    other proprietary or intellectual property rights, or rights of  publicity
    or privacy, or result in the violation of any applicable law or  regulation.
</p>
<p>
    22.  Hyperlinks
</p>
<p>
    The Site, Application and Services may contain links  to third-party
    websites or resources. You acknowledge and agree that Intelli  Edtech is not
    responsible or liable for: (i) the availability or accuracy of  such
    websites or resources; or (ii) the content, products, or services on or
    available from such websites or resources. Links to such websites or
    resources  do not imply any endorsement by Intelli Edtech of such websites
    or resources or  the content, products, or services available from such
    websites or resources.  You acknowledge sole responsibility for and assume
    all risk arising from your  use of any such websites or resources or the
    Content, products or services on  or available from such websites or
    resources.
</p>
<p>
    23.  Feedback
</p>
<p>
    We welcome and encourage you to provide feedback,  comments and suggestions
    for improvements to the Site, Application and Services  ("Feedback "). You
    may  submit Feedback by emailing us, through the "Contact" section of the
    Site and Application, or by other means of communication. You acknowledge
    and  agree that all Feedback you give us will be the sole and exclusive
    property of  Intelli Edtech and you hereby irrevocably assign to Intelli
    Edtech and agree to  irrevocably assign to Intelli Edtech all of your right,
    title, and interest in  and to all Feedback, including without limitation
    all worldwide patent,  copyright, trade secret, moral and other proprietary
    or intellectual property  rights therein, and waive any moral rights you may
    have in such Feedback. At Intelli  Edtech's request and expense, you will
    execute documents and take such further  acts as Intelli Edtech may
    reasonably request to assist Intelli Edtech to  acquire, perfect, and
    maintain its intellectual property rights and other legal  protections for
    the Feedback.
</p>
<p>
    24.  Copyright Policy
</p>
<p>
    Intelli Edtech respects copyright law and expects  its users to do the same.
    It is Intelli Edtech's policy to terminate in  appropriate circumstances the
    Intelli Edtech Accounts of Members or other  account holders who repeatedly
    infringe or are believed to be repeatedly  infringing the rights of
    copyright holders.
</p>
<p>
    25.  Term and Termination, Suspension and Other Measures
</p>
<p>
    1.      Term
</p>
<p>
    This Agreement shall be effective for  till the time Members access or use
    the Site, Application or Services or  by downloading or posting any content
    from or on the Site, via the Application  or through the Services Until such
    time when you or Intelli Edtech  terminate the Agreement as described below.
</p>
<p>
    2.      Termination for convenience
</p>
<p>
    You may terminate this Agreement at any time via the  "Cancel Account"
    feature on the Site or by sending us an email. If  you cancel your Intelli
    Edtech Account, any confirmed enrolment will be  automatically cancelled and
    any refund will depend upon the terms of the  applicable cancellation
    policy. Without limiting our rights specified below,  Intelli Edtech may
    terminate this Agreement for convenience at any time by  giving you 30 days'
    notice via email to your registered email address.
</p>
<p>
    3.      Termination for breach, suspension and other measures
</p>
<p>
    1.      Intelli Edtech may immediately, without notice terminate this
    Agreement if (i) you have materially breached these Terms or our Policies,
    including but not limited to any breach of your warranties outlined in these
    Terms or breach of the " User Conduct " provisions in these Terms, (ii) you
    have provided inaccurate,  fraudulent, outdated or incomplete information
    during the Intelli Edtech  Account registration, or Listing process or
    thereafter, (iii) you have violated  applicable laws, regulations or third
    party rights, or (iv) Intelli Edtech  believes in good faith that such
    action is reasonably necessary to protect the  safety or property of other
    Members, Intelli Edtech or third parties, for fraud  prevention, risk
    assessment, security or investigation purposes.
</p>
<p>
    2.      In addition Intelli Edtech may deactivate or delay reviews, or other
    Member Content, cancel any pending or confirmed enrolments, limit your use
    of  or access to your Intelli Edtech Account and the Site, Application or
    Services,  temporarily or permanently revoke any special status associated
    with your  Intelli Edtech Account, or temporarily or permanently suspend
    your Intelli  Edtech Account if (i) you have breached these Terms or our
    Policies, including  material and nonmaterial breaches and receiving poor
    ratings from  Students, or (ii) Intelli Edtech believes in good faith that
    such action is  reasonably necessary to protect the safety or property of
    Members, Intelli  Edtech or third parties, for fraud prevention, risk
    assessment, security or  investigation purposes.
</p>
<p>
    3.      In case of non-material breaches and where appropriate, you will be
    given notice of any measure by Intelli Edtech and an opportunity to resolve
    the  issue to Intelli Edtech's reasonable satisfaction.
</p>
<p>
    4.      Consequences of Termination
</p>
<p>
    1.      If we take any of the measures described above we may (i)
    communicate  to the Students that a pending or confirmed enrolment has been
    cancelled, (ii)  refund the Students in full for any and all confirmed
    enrolments, irrespective  of pre-existing cancellation policies, (iii)
    support the Students, on an  exceptional basis, in finding potential
    alternative Courses, and (iv) you will  not be entitled to any compensation
    for confirmed enrolments that were  cancelled.
</p>
<p>
    2.      If You or We terminate this Agreement, we do not have an obligation
    to  delete or return to you any of your Member Content, including but not
    limited  to any reviews or Feedback. When this Agreement has been
    terminated, you are  not entitled to a restoration of your Intelli Edtech
    Account or any of your  Member Content. If your access to or use of the
    Site, Application and Services  has been limited or your Intelli Edtech
    Account has been suspended or this  Agreement has been terminated by us, you
    may not register a new Intelli Edtech  Account or attempt to access and use
    the Site, Application and Services through  other Intelli Edtech Accounts.
</p>
<p>
    26.  Survival
</p>
<p>
    If you or we terminate this Agreement, the clauses  of these Terms that
    reasonably should survive termination of the Agreement will  remain in
    effect.
</p>
<p>
    27.  Disclaimers
</p>
<p>
    1.      If you choose to use the Site, Application, Services or Collective
    Content, you do so at your sole risk. You acknowledge and agree that Intelli
    Edtech does not have an obligation to conduct background or character checks
    on  any Member, but may conduct such background or character checks in its
    sole  discretion. If we choose to conduct such checks, to the extent
    permitted by  applicable law, we disclaim warranties of any kind, either
    express or implied,  that such checks will identify prior misconduct by a
    user or guarantee that a  user will not engage in misconduct in the future.
</p>
<p>
    2.      The Site, Application, Services and Collective Content are provided
    "as is", without warranty of any kind, either express or implied.  Without
    limiting the foregoing, Intelli Edtech explicitly disclaims any  warranties
    of merchantability, fitness for a particular purpose, quiet  enjoyment or
    non-infringement, and any warranties arising out of course of  dealing or
    usage of trade. Intelli Edtech makes no warranty that the site,
    application, services, collective content, including, but not limited to,
    any  Courses, will meet your requirements or be available on an
    uninterrupted,  secure, or error-free basis. Intelli Edtech makes no
    warranty regarding the  quality of any Listings, Courses, teachers,
    Students, the Services or  Collective Content or the accuracy, timeliness,
    truthfulness, completeness or  reliability of any collective content
    obtained through the Site, Application or  Services.
</p>
<p>
    3.      No advice or information, whether oral or written, obtained from
    Intelli Edtech or through the Site, Application, Services or Collective
    Content, will create any warranty not expressly made herein.
</p>
<p>
    4.      You are solely responsible for all of your communications and
    interactions with other users of the Site, Application or Services and with
    other persons with whom you communicate or interact as a result of your use
    of  the Site, Application or Services, including, but not limited to, any
    Students.  You understand that Intelli Edtech does not make any attempt to
    verify the  statements of users of the Site, Application or Services or to
    review any  Course. Intelli Edtech makes no representations or warranties as
    to the conduct  of users of the Site, Application or Services or their
    compatibility with any  current or future users of the Site, Application or
    Services. You agree to take  reasonable precautions in all communications
    and interactions with other users  of the site, application or services and
    with other persons with whom you  communicate or interact as a result of
    your use of the Site, Application Or  Services, including, but not limited
    to, Students, particularly if you decide  to meet offline or in person
    regardless of whether such meetings are organized  by Intelli Edtech.
    Intelli Edtech explicitly disclaims all liability for any  act or omission
    of any Student or other third party.
</p>
<p>
    28.  Limitation of Liability
</p>
<p>
    1.      You acknowledge and agree that, to the maximum extent permitted by
    law, the entire risk arising out of your access to and use of the Site,
    Application, Services and Collective Content, your enrolment of any Course
    via  the Site, Application and Services, and any contact you have with other
    users  of Intelli Edtech whether in person or online remains with you.
    Neither Intelli Edtech nor any other party involved in creating, producing,
    or  delivering the Site, Application, Services, Collective Content will be
    liable  for any incidental, special, exemplary or consequential damages,
    including lost  profits, loss of data or loss of goodwill, service
    interruption, computer  damage or system failure or the cost of substitute
    products or services, or for  any damages for personal or bodily injury or
    emotional distress arising out of  or in connection with these terms, from
    the use of or inability to use the  Site, Application, Services or
    Collective Content, from any communications,  interactions or meetings with
    other users of the Site, Application, or Services  or other persons with
    whom you communicate or interact as a result of your use  of the Site,
    Application, Services, or from your Enrolment of any Course via  the Site,
    Application And Services, whether based on warranty, contract, tort
    (including negligence), product liability or any other legal theory, and
    whether or not Intelli Edtech has been informed of the possibility of such
    damage, even if a limited remedy set forth herein is found to have failed of
    its essential purpose.
</p>
<p>
    2.      In no event will Intelli Edtech's aggregate liability arising out of
    or in connection with these Terms and your use of the Site, Application and
    Services including, but not limited to, your enrolment of any course via the
    Site, Application and Services, or from the use of or inability to use the
    Site, Application, Services, or Collective Content and in connection with
    any  course or interactions with any other members, exceed the amounts you
    have paid  or owe for enrolments via the Site, Application and Services as a
    student in  the three (3) month period prior to the event giving rise to the
    liability. The  limitations of damages set forth above are fundamental
    elements of the basis of  the bargain between Intelli Edtech and you.
</p>
<p>
    29.  Indemnification
</p>
<p>
    You agree to release, defend, indemnify, and hold  Intelli Edtech and its
    affiliates and subsidiaries, and their officers,  directors, employees and
    agents, harmless from and against any claims,  liabilities, damages, losses,
    and expenses, including, without limitation,  reasonable legal and
    accounting fees, arising out of or in any way connected  with (a) your
    access to or use of the Site, Application, Services, or  Collective Content
    or your violation of these Terms; (b) your Member Content;  (c) your (i)
    interaction with any Member, (ii) enrolment of an Course;  including but not
    limited to any injuries, losses, or damages (compensatory,  direct,
    incidental, consequential or otherwise) of any kind arising in  connection
    with or as a result of an enrolment or attending of a Course.
</p>
<p>
    30.  Entire Agreement
</p>
<p>
    Except as they may be supplemented by additional  Intelli Edtech policies,
    guidelines, standards, or terms for a specific  product, feature, service or
    offering, these Terms constitute the entire and  exclusive understanding and
    agreement between Intelli Edtech and you regarding  the Site, Application,
    Services, Collective Content (excluding Payment  Services), and any
    enrolments made via the Site, Application and Services  (excluding Payment
    Services), and these Terms supersede and replace any and all  prior oral or
    written understandings or agreements between Intelli Edtech and  you
    regarding enrolments, the Site, Application, Services, and Collective
    Content (excluding Payment Services).
</p>
<p>
    31.  Notices
</p>
<p>
    Any notices or other communications permitted or  required hereunder,
    including those regarding modifications to these Terms,  will be in writing
    and given by Intelli Edtech (i) via email (in each case to  the address that
    you provide) or (ii) by posting to the Site or via the  Application. For
    notices made by e-mail, the date of receipt will be deemed the  date on
    which such notice is transmitted.
</p>
<p>
    32.  Governing Law and Jurisdiction
</p>
<p>
    1.      These Terms and your use of the Services will be interpreted in
    accordance with the laws of India excluding its rules on conflicts of laws.
    You  and we agree to submit any dispute arising under these Terms to the
    personal  jurisdiction of a court located in Bangalore for any actions for
    which the  parties retain the right to seek injunctive or other equitable
    relief in a  court of competent jurisdiction to prevent the actual or
    threatened  infringement, misappropriation or violation of a party's
    copyrights, trademarks,  trade secrets, patents, or other intellectual
    property rights.
</p>
<p>
    2.      Any dispute, claim or controversy arising out of or relating to this
    Terms including the determination of the scope or applicability of this
    Terms  to arbitrate, or your use of the Application or information to which
    it gives  access, shall be determined by arbitration in India, before a sole
    arbitrator  mutually appointed by Members and Intelli Edtech. Arbitration
    shall be  conducted in accordance with the Arbitration and Conciliation Act,
    1996. The  seat of such arbitration shall be Bangalore. All proceedings of
    such  arbitration, including, without limitation, any awards, shall be in
    the English  language. The award shall be final and binding on the parties
    to the dispute.
</p>
<p>
    3.      If you are a resident of the European Economic Area (EEA) you have
    the  right to complain to a Data Protection Authority about our collection
    and use  of your personal information. For more information, please contact
    your local  data protection authority in the EEA.
</p>
<p>
    33.  No Waiver
</p>
<p>
    The failure of Intelli Edtech to enforce any right  or provision of these
    Terms will not constitute a waiver of future enforcement  of that right or
    provision. The waiver of any such right or provision will be  effective only
    if in writing and signed by a duly authorized representative of  Intelli
    Edtech. Except as expressly set forth in these Terms, the exercise by
    either party of any of its remedies under these Terms will be without
    prejudice  to its other remedies under these Terms or otherwise. If for any
    reason a court  of competent jurisdiction finds any provision of these Terms
    invalid or  unenforceable, that provision will be enforced to the maximum
    extent  permissible and the other provisions of these Terms will remain in
    full force  and effect.
</p>
<p>
    34.  Miscellaneous
</p>
<p>
    1.      Intelli Edtech shall have no liability of any nature, whether in
    contract, or otherwise, for any losses whatsoever and howsoever caused, from
    or  in any manner connected with any of the Services provided by Us.
</p>
<p>
    2.      Intelli Edtech is not liable for any failure or delay of performance
    (or otherwise) arising out of a cause beyond Intelli Edtech’s reasonable
    control.
</p>
<p>
    3.      You may not assign or transfer these Terms, by operation of law or
    otherwise, without Intelli Edtech's prior written consent. Any attempt by
    you  to assign or transfer these Terms, without such consent, will be null
    and of no  effect. Intelli Edtech may assign or transfer these Terms, at its
    sole  discretion, without restriction. Subject to the foregoing, these Terms
    will  bind and inure to the benefit of the parties, their successors and
    permitted  assigns.
</p>
<p>
    4.      Intelli Edtech reserves the right at any time and from time to time
    to  modify or discontinue, temporarily or permanently, the Services (or any
    part  thereof) provided by Us with or without notice. You agree that Intelli
    Edtech  shall not be liable to You or to any third party for any
    modification,  suspension or discontinuance of such Services. It is Your
    responsibility to  review these Terms periodically for updates/changes.
</p>
<p>
    5.      You agree not to reproduce, duplicate, copy, sell, resell or exploit
    for any commercial purposes, any portion of the information provided by us,
    including any intellectual property rights of Intelli Edtech or any person
    firm  or corporation having posted information for availability through the
    Services  provided by us.
</p>
<p>
    6.      You agree that in the event Your post or Your information violates
    any  provision of this Terms, We shall have the right to refuse to provide
    You or  any person acting on Your behalf, access to the Site and
    Application, terminate  and/ or suspend Your access if applicable in the
    future.
</p>
        </p>
      </div>
    </>
  );
}

export default TnC;
