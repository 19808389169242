import React from 'react'
import CoverBackground from '../components/CoverBackground'
import "../styles/service.css"
import ResourceHubComp from '../components/ResourceHubComp'

function Resource() {
  return (
    <div className='main_container'>
    <CoverBackground />
    <div className='main_container_part'>
     <ResourceHubComp />
     </div>
     </div>
  )
}

export default Resource
