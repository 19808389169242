export const questionsTypeArray = [
  { id: 1, title: "Objective", type: "mcq" },
  { id: 2, title: "Subjective", type: "subjective" },
  { id: 3, title: "MCQ Multiple", type: "mcqmultiple" },
  { id: 4, title: "True/False", type: "true/false" },
  { id: 5, title: "Attach File", type: "attach" },
  { id: 6, title: "Slider", type: "slider" },
  { id: 7, title: "Fill Text", type: "fillText" },
  { id: 8, title: "Fill Dropdown", type: "dropdown" },
  { id: 9, title: "Table", type: "table" },
  { id: 10, title: "Paragraph", type: "paragraph" },
  { id: 11, title: "Match", type: "match" },
  { id: 12, title: "Sort", type: "sort" },
  { id: 13, title: "Classify", type: "classify" },
  { id: 14, title: "Graph", type: "graph" },
  { id: 15, title: "HotSpot", type: "hotSpot" },
  { id: 16, title: "Drawing", type: "drawing" },
  { id: 17, title: "Label Drag", type: "labelDrag" },
  { id: 18, title: "Label Fill", type: "labelFill" },
];

export const paper = [
  { id: 1, papers: "Paper 1", value: "Paper1" },
  { id: 2, papers: "Paper 2", value: "Paper2" },
  { id: 3, papers: "Paper 3", value: "Paper3" },
  { id: 4, papers: "All", value: "All" },
];

export const level = [
  { id: 1, diificultyLevel: "Easy", value: "Easy" },
  { id: 2, diificultyLevel: "Medium", value: "Medium" },
  { id: 3, diificultyLevel: "Hard", value: "Hard" },
  { id: 4, diificultyLevel: "All", value: "All" },
];
export const pagination = ["1", "2", "3", "4", "5", "6"];
