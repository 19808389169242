import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/serviceComp.css";
import loader from "../assets/loader/loader.gif";
import "react-responsive-modal/styles.css";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";

function ResourceHubComp() {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTab, setLoadingTab] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [score, setScore] = useState(0);
  const [showMarkSchemesModal, setShowMarkSchemesModal] = useState(false);
  const [showNotAvailableModal, setShowNotAvailableModal] = useState(false);
  const [solutionData, setSolutionData] = useState({
    question: "",
    solution: "",
  });
  const [exams, setExams] = useState([]);
  const [selectedExamId, setSelectedExamId] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [chapters, setChapters] = useState([]);
  const [selectedChapterId, setSelectedChapterId] = useState("");
  const [topics, setTopics] = useState([]);
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const navigate = useNavigate();
  const [questionType, setQuestionType] = useState("multipleChoice");

  const getProgressBarWidth = (level) => {
    switch (level) {
      case "easy":
        return 30;
      case "medium":
        return 45;
      case "hard":
        return 90;
      default:
        return 0;
    }
  };

  const API_BASE_URL =
    "https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/content-pannel/v1";

  useEffect(() => {
    fetchExams();
    fetchSubjects();
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/exam/getExams`);
      setExams(response.data.data);
      setLoadingTab(false);
      // console.log(response.data.data)
    } catch (error) {
      console.error("Error fetching exams:", error);
      setLoadingTab(false);
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/subject/getSubjects`);
      setSubjects(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
      toast.error("Failed to fetch resource. Please try again.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl =
          "https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/content-pannel/v1/random/question?tags=revision";
        const response = await axios.get(apiUrl);
        setQuestions(response.data);
        console.log(response.data);
        setLoading(false); // Set loading to false when data is received
      } catch (error) {
        console.error("Error fetching questions:", error);
        setLoading(false); // Set loading to false in case of an error
        toast.error("Failed to fetch questions. Please try again.");
      }
    };

    fetchData();
  }, []);

  const handleMarkSchemes = (question) => {
    console.log(question);
    setSolutionData(question);
    setShowMarkSchemesModal(true);
  };

  const handleNotAvailable = () => {
    setShowNotAvailableModal(true);
  };

  const handleCloseMarkSchemesModal = () => {
    setShowMarkSchemesModal(false);
  };

  const handleCloseNotAvailableModal = () => {
    setShowNotAvailableModal(false);
  };

  const handleOptionChange = (questionIndex, optionIndex) => {
    // Add your logic here to handle option changes and update state if needed
    setSelectedOptions({
      ...selectedOptions,
      [questionIndex]: optionIndex,
    });
  };

  const handleSubjectClick = (exam, subject) => {
    // Navigate to the Chapter component and pass data
    navigate(`/chapter`, {
      state: {
        exam,
        subject,
      },
    });
  };
  const haldleQuestionType = async (questionType) => {
    // console.log(questionType)
    setLoading(true); // Set loading to false when data is received

    setQuestionType(questionType);
    try {
      const apiUrl = `https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/content-pannel/v1/random/question?questionType=${questionType}`;
      const response = await axios.get(apiUrl);

      setQuestions(response.data);
      console.log(response.data);
      setLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error fetching questions:", error);
      setLoading(false); // Set loading to false in case of an error
      toast.error("Failed to fetch questions. Please try again.");
    }
  };
  return (
    <div
      id="ResourceHub"
      className="landing_page_resource_hub_slider_container"
    >
      <Modal
        open={showMarkSchemesModal}
        onClose={handleCloseMarkSchemesModal}
        center
        styles={{
          modal: {
            width: "80%",
            height: "80%",
            overflowX: "scroll",
            borderRadius: "10px",
          },
        }}
      >
        {/* <div style={{ display: 'flex',flexDirection:"column", height: '100%' }}> */}
        {/* Left side: Display the question */}
        {/* <div style={{ width:"100%", padding: '10px',height:"auto", borderBottom: '1px solid #ccc', overflowX: 'scroll' }}> */}
        <h3>Question {currentQuestionIndex + 1}</h3>
        <div dangerouslySetInnerHTML={{ __html: solutionData.question }} />
        {/* Add any additional details you want to display */}
        {solutionData.options &&
          solutionData.options.map((option, optionIndex) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              key={optionIndex}
            >
              <div style={{ marginBottom: "5px" }}>
                {String.fromCharCode(65 + optionIndex)}.
              </div>
              <div dangerouslySetInnerHTML={{ __html: option }} />
            </div>
          ))}
        {/* </div> */}

        {/* Right side: Display the solution */}
        {/* <div style={{ width:"100%", padding: '10px', borderBottom: '1px solid #ccc',height:"auto", overflowY: 'scroll' }}> */}
        <h3>Solution</h3>
        <div dangerouslySetInnerHTML={{ __html: solutionData.solution }} />
        {/* Add any additional details you want to display */}
        {/* </div> */}
        {/* </div> */}
      </Modal>

      <Modal
        open={showNotAvailableModal}
        onClose={handleCloseNotAvailableModal}
        center
        styles={{
          modal: { width: "80%", height: "35%", borderRadius: "10px" },
        }}
      >
        <div style={{ justifyContent: "center", alignContent: "center" }}>
          <h2
            style={{ fontSize: "28px", fontWeight: "700", textAlign: "center" }}
          >
            Coming Soon{" "}
          </h2>
          <p
            style={{
              fontSize: "16px",
              margin: "15px 0px",
              textAlign: "center",
            }}
          >
            We are working hard to get this up and running
          </p>
        </div>
      </Modal>

      <h3 className="landing_page_resource_hub_slider_container_hed_1">
        Resource Hub
      </h3>
      <p className="landing_page_resource_hub_slider_container_hed_2">
        {" "}
        Unlock your potential with our premium resources for sale. Elevate your
        learning experience with carefully curated materials designed to inspire
        and empower
      </p>
      {loadingTab && (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img style={{ height: "70px" }} src={loader} alt="loading" />
        </div>
      )}

      <div className="exam_cards_container">
        {exams.map((exam) => (
          <div className="exam_card" key={exam._id}>
            <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
              {exam.examName}
            </h2>
            {/* Add any additional details you want to display */}
            {/* <img src={exam.thumbnail} alt={exam.examName} /> */}
            {/* <p>{exam.examDescription}</p> */}
            {subjects
              .filter((subject) => subject.exam === exam._id)
              .map((subject) => (
                <button
                  className="newexambtn"
                  key={subject._id}
                  onClick={() => handleSubjectClick(exam, subject)}
                >
                  {subject.subjectName}
                </button>
              ))}
          </div>
        ))}
      </div>

      <h2
        style={{
          marginTop: "20px",
          fontSize: "38px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Practice Exam Style Questions
      </h2>
      <p className="landing_page_resource_hub_slider_container_hed_2">
        Thousands of exam style questions, <br /> filtered by Subject, topic,
        sub-topic & difficulty level. Detailed and easy to understand with
        mark-schemes{" "}
      </p>
      {loading && (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img style={{ height: "70px" }} src={loader} alt="loading" />
        </div>
      )}

      {Array.isArray(questions) && questions.length > 0 && (
        <div
          style={{
            width: "95%",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <div className="toggle-container">
            <button
              className={`toggle-btn ${
                questionType === "multipleChoice" ? "active" : ""
              }`}
              onClick={() => haldleQuestionType("multipleChoice")}
            >
              Objective
            </button>
            <button
              className={`toggle-btn ${
                questionType === "subjective" ? "active" : ""
              }`}
              onClick={() => haldleQuestionType("subjective")}
            >
              Subjective
            </button>
          </div>
          {questions &&
            questions.map((question, index) => (
              <div key={index}>
                <div className="flex items-center p-5">
                  <h3 className="text-4xl">Question {index + 1}</h3>
                  <button
                    style={{
                      fontSize: "14px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                    className={`ml-5 p-2 ${
                      question.calculator ? "bg-green-500" : "bg-rd-500"
                    }`}
                  >
                    {question.calculator
                      ? "calculator Allowed"
                      : "calculator Not allowed"}
                  </button>
                </div>
                <div className="question_list">
                  <div className="questionwidth">
                    <div
                      style={{ fontWeight: "bold" }}
                      dangerouslySetInnerHTML={{ __html: question.question }}
                    />

                    {question.options &&
                      question.options.map((option, optionIndex) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                          key={optionIndex}
                        >
                          <input
                            type="radio"
                            style={{ margin: "0px 10px 10px 10px  " }}
                            name={`question_${index}`}
                            value={option}
                            checked={selectedOptions[index] === optionIndex}
                            onChange={() =>
                              handleOptionChange(index, optionIndex)
                            }
                          />
                          <div dangerouslySetInnerHTML={{ __html: option }} />
                        </div>
                      ))}
                  </div>
                  <div
                    className="questionwidthButtons"
                    style={{
                      width: "20%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px",
                    }}
                  >
                    <div
                      className="progress-bar progress-bar-info"
                      role="progress"
                      aria-valuenow={getProgressBarWidth(question.level)}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: `${getProgressBarWidth(question.level)}%`,
                        height: "100%",
                        borderRadius: "5px",
                        padding: "5px",
                        color: "white",
                        backgroundColor: "#032539",
                        marginBottom: "5px",
                      }}
                    >
                      {question.level}
                    </div>
                    <button
                      onClick={() => handleMarkSchemes(question)}
                      style={{
                        padding: "4px 5px",
                        fontSize: "14px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >
                      <span role="img" aria-label="PDF icon">
                        📄
                      </span>
                      mark-schemes
                    </button>
                    <button
                      style={{
                        padding: "4px 5px",
                        fontSize: "14px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >
                      Connect Instant Teacher
                    </button>
                    <button
                      onClick={handleNotAvailable}
                      style={{
                        padding: "4px 5px",
                        fontSize: "14px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >
                      Check With IntelliAI
                    </button>
                  </div>
                </div>
                <br />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default ResourceHubComp;
