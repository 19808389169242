import React, { useEffect, useState } from 'react';

const StaticHotspotDragableInput = ({ getPostion = () => { }, position, text, children }) => {

    return (
        <div
            className='drag-parent'
            style={{
                top: `${position?.y}px`,
                left: `calc(50% + ${position?.x}px)`,
                position: 'absolute', zIndex: "99",
                cursor: 'grab', transform: 'translateX(-50%)',
                height: "40px",
                width: "40px",
                background: text == "true" ? "rgba(105,255, 150, .4)" : "rgba(135,206, 235, .3)",
                borderRadius: "50%",
                border: "solid 1px blue",
                display: "flex", justifyContent: "center", alignItems: "center"
            }}
        >
            {children}
        </div>
    );
};

export default StaticHotspotDragableInput;
