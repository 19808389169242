import React, { useState } from "react";
import "./allReview.css";
import Modal from "react-responsive-modal";

const TrueFalse = ({ data, button, type }) => {
  const [showMarkSchemesModal, setShowMarkSchemesModal] = useState(false);

  const handleMarkSchemes = (question) => {
    setShowMarkSchemesModal(true);
  };

  const handleCloseMarkSchemesModal = () => {
    setShowMarkSchemesModal(false);
  };

  let dataAns = data?.Statements?.filter((i) => i?.ans === true).map((i) => (
    <div
      className="question-content"
      dangerouslySetInnerHTML={{
        __html: i.Statement,
      }}
    ></div>
  ));

  return (
    <>
      <Modal
        open={showMarkSchemesModal}
        onClose={handleCloseMarkSchemesModal}
        center
        styles={{
          modal: {
            width: "95%",
            height: "80%",
            borderRadius: "10px",
            backgroundColor: "#E3F2FD",
          },
        }}
      >
        <div
          style={{
            flex: 1,
            padding: "30px",
            overflowY: "auto",
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: 10,
            marginTop: 30,
          }}
        >
          <h3>
            Question
            {/* {currentQuestionIndex + 1}  */}:
          </h3>
          <div>
            <div
              className="question-content"
              dangerouslySetInnerHTML={{
                __html: data?.question_content,
              }}
            ></div>
          </div>
        </div>

        {/* Put Your soluction Code Here */}
        <div
          style={{
            flex: 1,
            padding: "30px",
            overflowY: "auto",
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: 10,
            marginTop: 30,
          }}
        >
          <div className="options-container">
            <p className="text">Correct Option: </p>&nbsp;&nbsp;&nbsp;&nbsp;
            <>
              {dataAns?.map((i) => {
                return <div className="optionAll bgStyle">{i}</div>;
              })}
            </>
          </div>
        </div>
      </Modal>
      <div className="flex justify-between">
        <div style={{ width: "75%" }} className="items-center gap-5">
          <div className="flex-1">
            <div className="question-box w-full">
              <div
                className="question-content"
                dangerouslySetInnerHTML={{
                  __html: data?.question_content,
                }}
              ></div>
            </div>
          </div>

          <div className=" mt-16">
            {data?.Statements?.map((item) => (
              <div className="border border-solid border-gray-400 rounded-lg mt-2 p-4 flex justify-between items-center">
                {item?.Statement}
                <div>
                  <div className="flex justify-center items-center">
                    <div className="flex mr-4">
                      <input
                        type="radio"
                        id={"radio-true"}
                        name={`custom-radio`}
                        value="true"
                        checked={false}
                        className="w-6 h-6"
                      />
                      <label
                        className="radioText ml-4"
                        // htmlFor={`radio-true-${item.id}`}
                      >
                        True
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        id={"radio-true"}
                        name={`custom-radio`}
                        value="true"
                        checked={false}
                        className="w-6 h-6"
                      />
                      <label
                        className="radioText ml-4"
                        // htmlFor={`radio-true-${item.id}`}
                      >
                        False
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-0.5 bg-gray-200"></div>
        <div className="w-64">
          <div style={{ color: "black" }}>
            Minimum Marks:
            {data.mark}
          </div>
          <div style={{ marginTop: 10 }}>
            Negative Marks:
            {/* {data.level} */}
          </div>
          <div className="flex" style={{ marginTop: 10 }}>
            Question Type: &nbsp;
            <div
              style={{
                color:
                  data?.level === "Easy"
                    ? "#3771C8"
                    : data?.level === "Medium"
                    ? "#1FAF38"
                    : "#F71D2C",
              }}
            >
              {data.level}
            </div>
          </div>{" "}
          <div
            style={{
              backgroundColor:
                data.level === "Easy"
                  ? "rgba(0, 0, 255, 0.3)"
                  : data.level === "Medium"
                  ? "rgba(0, 255, 0, 0.5)"
                  : data.level === "Hard"
                  ? "rgba(243, 76, 48, 0.96)"
                  : "transparent",
              width:
                data.level === "Easy"
                  ? "30%"
                  : data.level === "Medium"
                  ? "45%"
                  : data.level === "Hard"
                  ? "90%"
                  : "0%",
              borderWidth: 1,
              borderColor:
                data.level === "Easy"
                  ? "rgba(0, 0, 255, 0.3)"
                  : data.level === "Medium"
                  ? "rgba(0, 255, 0, 0.5)"
                  : data.level === "Hard"
                  ? "rgba(243, 76, 48, 0.96)"
                  : "transparent",
              borderStyle: "solid",
              borderRadius: "9px",
              marginBottom: "10px",
              padding: "12px",
              marginTop: "10px",
            }}
          />
          <div className="flex flex-col">
            <button
              onClick={() => handleMarkSchemes(data)}
              style={{
                padding: "8px 5px",
                fontSize: "14px",
                borderRadius: "10px",
                fontWeight: "400",
                marginTop: "10px",
                color: "black",
                backgroundColor: "#BAB5EA",
              }}
            >
              <span role="img" aria-label="PDF icon" className="mr-1">
                📄
              </span>
              Mark Scheme
            </button>
            {button}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrueFalse;
