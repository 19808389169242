import React, { useEffect, useState } from "react";
import "../styles/serviceComp.css";

import {
  examsData,
  gradeData,
  languageData,
  subjectsData,
} from "../utils/data";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import featuresCards from "../utils/cards";
import Slider from "react-slick";

import courseImg from "../assets/images/exam.jpeg";
import Modal from "react-responsive-modal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginModal from "./LoginModal";

function ExamSeriesComp() {
  const navigate = useNavigate();
  const [modalInfo, setModalInfo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalL, setShowModalL] = useState(false);
  const handleToExam = () => {
    if (
      !localStorage.getItem("isLoggedIn") ||
      localStorage.getItem("isLoggedIn") == "false"
    ) {
      setShowModalL(true);
    } else {
      navigate("/exam");
    }
  };
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1070,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div id="ExamSeries" className="service_comp_mainContainer_slider">
      <h1 style={{ color: featuresCards[3].color }}>Exam Series</h1>
      <p>
        {" "}
        Unlock your potential with our premium Test. Elevate your learning
        experience with carefully curated materials designed to inspire and
        empower.
      </p>
      {showModalL && (
        <LoginModal showModal={showModalL} setShowModal={setShowModalL} />
      )}
      <Slider {...settings}>
        {examsData.map((elem, ind) => {
          return (
            <div
              key={ind}
              onClick={(e) => {
                setModalInfo(elem);
                setShowModal(true);
              }}
              className="service_comp_mainContainer_slider_main"
            >
              <img
                src={elem.image ? elem.image : courseImg}
                className="landing_page_exam_hub_slider_container_item_img"
              />
              <h3>{elem.name}</h3>
              <p>
                {" "}
                Marks:{elem.marks} | {elem.time}min
              </p>
              <p>
                {" "}
                Marks:{elem.marks} | {elem.time}min
              </p>
              <button
                onClick={() => {
                  handleToExam();
                }}
              >
                Attempt Now
              </button>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default ExamSeriesComp;
