import img1 from "../assets/images/cardImg1.png"
import img2 from "../assets/images/cardImg2.png"
import img3 from "../assets/images/cardImg3.png"
import img4 from "../assets/images/cardImg4.png"
import img5 from "../assets/images/cardImg5.png"
import img6 from "../assets/images/cardImg6.png"

const featuresCards=[
    {
        image:img1,
        heading:'Doubts solved, confidence evolved!',
        description:"Unlock learning barriers with our dedicated doubt-solving platform, ensuring personalized attention and expert guidance. Elevate your understanding and excel with confidence in every subject.",
        color:"rgba(87, 154, 255, 0.84)",
        redirectUrl:"/doubt-solving",
        id:"#DoubtSolving"
    },
    {
        image:img2,
        heading:'Your success, our priority!',
        description:"Empower your academic journey with our online private tutors. Tailored lessons, one-on-one support, and flexible scheduling for a personalized learning experience that fits your unique needs.",
        color:"#F77",
        redirectUrl:"/online-tutoring",
        id:"#OnlineTutoring"
    },
    {
        image:img4,
        heading:'Where learning begins, at the heart of your home.',
        description:"Bring learning home with our exceptional home tutors. Tailored to your schedule and individual needs, our tutors provide a nurturing environment for academic success within the comfort of your home.",
        color:"rgba(255, 188, 87, 0.84)",
        redirectUrl:"/home-tutoring"  ,
        id:"#HomeTutoring"
    },
    {
        image:img3,
        heading:'Excel Every Exam',
        description:"Elevate your preparation with our comprehensive exam series. Designed for success, our series offers strategic insights and practice, ensuring you're ready to excel on exam day.",
        color:"rgba(255, 188, 87, 0.84)",
        redirectUrl:"/exam-series"  ,
        id:"#ExamSeries"
    },
    {
        image:img6,
        heading:'Transforming Today, Shaping Tomorrow.',
        description:"Unleashing the power of intelligent solutions, we pioneer innovation in AI for a smarter future, transforming industries and shaping a new era of possibilities.",
        color:"rgba(87, 154, 255, 0.84)",
        redirectUrl:"/intelli-ai"  ,
        id:"#IntelliAi"
    },
    {
        image:img5,
        heading:'Empowering Your Journey with Knowledge.',
        description:"Your go-to destination for curated knowledge and tools. Unlock a wealth of resources, empowering you with the information and skills needed for success in any field.",
        color:"rgba(255, 155, 155, 0.84)",
        redirectUrl:"/resource-hub"  ,
        id:"#ResourceHub"
    },
]
export default featuresCards;