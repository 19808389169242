import React, { useState } from "react";
import "../styles/featuresCards.css";

import featuresCards from "../utils/cards";
import backImg from "../assets/icons/back.png";

import { useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import DoubtSolving from "../screens/DoubtSolving";
import OnlineTutoring from "../screens/OnlineTutoring";
import HomeTutor from "../screens/HomeTutor";
import ExamSeries from "../screens/ExamSeries";
import Resource from "../screens/Resource";
import AiScreen from "../screens/AiScreen";

function Cards() {
  const [showDetailedView, setShowDetailedView] = useState("-1");
  const navigate = useNavigate();

  const handleNotAvailable = () => {
    toast.error("The requested page is under maintainence !");
  };

  const setIndexHandler = (ind) => {
    setShowDetailedView(`${ind}`);
  };
  // if(showDetailedView=="-1"){

  return (
    <>
      <div className="features_cards">
        {featuresCards.map((elem, ind) => {
          return (
            <a
              onClick={() => {
                setIndexHandler(ind);
              }}
              href={`${elem.id}`}
            >
              <img src={elem.image} key={ind} className="features_cards_item" />
            </a>
          );
        })}
      </div>
      {/* }
 else{
    return(

        <div className='features_cards_detail'>
            <img onClick={()=>{setShowDetailedView("-1")}} className='features_cards_detail_back_img' src={backImg} alt="backg_img" />
        <div className='features_cards_detail_part_1'>
            <img src={featuresCards[showDetailedView].image}  className='features_cards_detail_part_1_img'/>
            <div className='features_cards_detail_part_1_part_2'>
            <h4 className='features_cards_detail_part_1_hed'>{ featuresCards[showDetailedView].heading}</h4>
            <p className='features_cards_detail_part_1_desc'>{ featuresCards[showDetailedView].description}</p>
            </div>
        </div>
        <button style={{backgroundColor: featuresCards[showDetailedView].color}} onClick={()=>{showDetailedView!=4? navigate( featuresCards[showDetailedView].redirectUrl) :handleNotAvailable()}} className='features_cards_detail_btn'>Know More</button>
    </div>
        ) */}
      {showDetailedView == 0 && <DoubtSolving />}
      {showDetailedView == 1 && <OnlineTutoring />}
      {showDetailedView == 2 && <HomeTutor />}
      {showDetailedView == 3 && <ExamSeries />}
      {showDetailedView == 4 && <AiScreen />}
      {showDetailedView == 5 && <Resource />}
    </>
  );

  // }
}

export default Cards;
