import React, { useEffect, useState } from 'react';

const StaticDragableInput = ({ position, color, width, children }) => {

    return (
        <div
            className='drag-parent'
            style={{ top: `${position?.y}px`, left: `calc(50% + ${position?.x}px)`, position: 'absolute', zIndex: "99", cursor: 'grab', transform: 'translateX(-50%)', background: color, width: width }}
        >
            {position?.line ? (
                <div style={{ width: '60px', height: '2px', background: 'black', position: 'absolute', left: '-60px', top: '25px' }}></div>
            ) : (
                <div style={{ width: '60px', height: '2px', background: 'black', position: 'absolute', right: '-60px', top: '25px' }}></div>
            )}
            {children}
        </div>
    );
};

export default StaticDragableInput;
