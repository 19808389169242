import React, { useEffect } from 'react'
import CoverBackground from '../components/CoverBackground'
import "../styles/service.css"
import AiScreenComp from '../components/AiScreenComp'
import InstructionVideo from '../components/InstructionVideo'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

function AiScreen() {
  const navigate=useNavigate()
  // useEffect(()=>{
  //   toast.error("The requested page is under maintainence !")
  //  navigate("/");
  // },[])
  return (
    <div className='main_container'>
   <CoverBackground />
   <div className='main_container_part'>
    <AiScreenComp />  
    <InstructionVideo />
    </div>
    </div>
  )
}

export default AiScreen
