import React from 'react'
import ReactPlayer from 'react-player'
function InstructionVideo() {
  return (
    <div className="intruction_video_player">

    <ReactPlayer width={"100%"} controls url='https://vod-progressive.akamaized.net/exp=1702853614~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F4995%2F11%2F299978182%2F1145430648.mp4~hmac=d45f401040927886df40929cbb69134cef76280d1ff10da8c1ad8baec62852d7/vimeo-prod-skyfire-std-us/01/4995/11/299978182/1145430648.mp4' />
    </div>
  )
}

export default InstructionVideo
