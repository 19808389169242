import React from "react";
import "../styles/footer.css";
import img from "../assets/icons/logo2.png";

// socials images
import facebook from "../assets/socials/facebook.png";
import instagram from "../assets/socials/instagram.png";
import linkedin from "../assets/socials/linkedin.png";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="footer_part1">
        <img className="footer_part1_img" src={img} alt="our logo" />
        <h4 className="footer_part1_txt">Not a normal app, its unique!</h4>
        <h4 className="footer_part1_txt">Services are provided everywhere.</h4>
        <div className="footer_part1_socials">
          <a href="https://www.facebook.com/profile.php?id=61562680961214" target="_blank" rel="noopener noreferrer">
          <img
            src={facebook}
            alt="social_icon"
            className="footer_part1_socials_img"
          />
          </a>
          <a href="https://www.linkedin.com/company/intelliedtechai" target="_blank" rel="noopener noreferrer">
          <img
            src={linkedin}
            alt="social_icon"
            className="footer_part1_socials_img"
          />
          </a>
          <a href="https://www.instagram.com/intelliedtech/" target="_blank" rel="noopener noreferrer">
          <img
            src={instagram}
            alt="social_icon"
            className="footer_part1_socials_img"
          />
          </a>
        </div>
        <h4 class="footer_part1_txt flex items-center">
          <span class="mr-1">Copyright |</span>
          <span class="text-2xl mr-1 ml-1">&#169;</span>
          <span class="mr-1 ml-1">| 2024</span>
          <span class="mr-1">STUTEACH INTELLI EDTECH PRIVATE LIMITED</span>
          <span>All rights reserved</span>
        </h4>
      </div>
      <div className="footer_part2">
        <li
          onClick={() => {
            navigate("/about-us");
          }}
          className="footer_part2_list"
        >
          About Us
        </li>
        <li
          onClick={() => {
            navigate("/blogs");
          }}
          className="footer_part2_list"
        >
          Blogs
        </li>
        <li
          onClick={() => {
            navigate("/terms-and-conditions");
          }}
          className="footer_part2_list"
        >
          Terms and Conditions
        </li>
        <li
          onClick={() => {
            navigate("/cancellation-and-refunds");
          }}
          className="footer_part2_list"
        >
          Cancellation/Refund Policy
        </li>
        <li
          onClick={() => {
            navigate("/privacy-policy");
          }}
          className="footer_part2_list"
        >
          Privacy Policy
        </li><li
          onClick={() => {
            navigate("/delete-account");
          }}
          className="footer_part2_list"
        >
          Delete Account
        </li>

        <li
          onClick={() => {
            navigate("/contact-us");
          }}
          className="footer_part2_list"
        >
          Contact Us
        </li>
        {/* <li onClick={()=>{navigate("/about-us")}}   className='footer_part2_list' >Help and Support</li> */}
      </div>
    </div>
  );
} //privacy-policy

export default Footer;
