import React from 'react'
import CoverBackground from '../components/CoverBackground'
import "../styles/service.css"
import DoubtSolvingComp from '../components/DoubtSolvingComp'
import InstructionVideo from '../components/InstructionVideo'

function DoubtSolving() {
  return (
    <div className='main_container'>
   <CoverBackground />
   <div className='main_container_part'>
    <DoubtSolvingComp />
    <InstructionVideo />
    </div>
    </div>
  )
}

export default DoubtSolving
