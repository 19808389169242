import React, { useEffect, useState } from 'react'
import "../styles/serviceComp.css"

import Select from "react-select";
import { boardOptions, gradeData, subjectsData } from '../utils/data';

import loader from "../assets/loader/loader.gif";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import featuresCards from '../utils/cards';

function AiScreenComp() {
  const [question, setQuestion] = useState("");
  const [selectedBoard,setselectedBoard]=useState(null)
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [loading,setLoading]=useState(false)
  const navigate=useNavigate()

  const getWidthOfDropdown = () => {
    if(window.innerWidth > 600){
      return  "242px";
    }else{
return  "80vw";
    } 
  };

  
  useEffect(() => {

    window.addEventListener('resize', getWidthOfDropdown);

    return () => {
      window.removeEventListener('resize', getWidthOfDropdown);
    };
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // Handle file as needed, e.g., set it in state
    setSelectedImage(file);
  };

  const handleStartNow = () => {
          setLoading(true)
    
    const questData = {
      class: selectedGrade,
      sub: selectedSubject,
      lang: 'English',
      actual_ques: question,
      status: "Active",
      studnetid: "65537cd0ad0cbdb0f06ac017",
    };
    const toastId = toast.loading("Finding the teacher");
    axios
      .post(
        "https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/task/tsv/apis/v1/create-que",
        questData
      )
      .then((res) => {
        setLoading(false)
        if (res.data.data === "no active teacher") {
          toast.dismiss(toastId);
          toast.error("No teacher found..");
        } else {
          toast.dismiss(toastId);
          console.log(res?.data.data._id);
          toast.success("Teacher found redirecting...");
          navigate("/aianswer", {
            state: {
              id:res?.data?.data?._id
            },
          });
        }
      });
  };

  return (
    <div  className='service_comp_mainContainer'>
      <h1 style={{color : featuresCards[4].color}}>Intelli AI</h1>
     
      <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                    width: "max-content",
                    minWidth: "100%",
                borderColor: "white",
                height: "48px",
                cursor: "pointer",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#6B4EFF",
              }),
            }}
            className="service_comp_mainContainer_dropdown"
            placeholder="Select Grade"
            options={boardOptions}
            onChange={(selectedOption) =>
              setselectedBoard(selectedOption.value)
            }
          />
  
      <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                    width: "max-content",
                    minWidth: "100%",
                borderColor: "white",
                height: "48px",
                cursor: "pointer",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#6B4EFF",
              }),
            }}
            className="service_comp_mainContainer_dropdown"
            placeholder="Select Grade"
            options={gradeData}
            onChange={(selectedOption) =>
              setSelectedGrade(selectedOption.value)
            }
          />
  
           <Select
            className="service_comp_mainContainer_dropdown"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                    width: "max-content",
                   minWidth: "100%",
                borderColor: "white",
                height: "48px",
                cursor: "pointer",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#6B4EFF",
              }),
            }}
            placeholder="Select Subject"
            options={subjectsData}
            onChange={(selectedOption) =>
              setSelectedSubject(selectedOption.value)
            }
          />
          <input
            className="service_comp_mainContainer_inp"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Try to ask a question"
            width={30}
          />

        <div className="service_comp_mainContainer_img">
          {selectedImage && (
              <img src={URL.createObjectURL(selectedImage)} alt="Selected" />
          )}
          <div className="service_comp_mainContainer_inp">
            <input type="file" onChange={handleImageChange} accept="image/*" />
          </div>
        </div>


        {loading===true ?
                <img className='service_comp_mainContainer_loader' src={loader} />
      :
          <button 
          style={{backgroundColor :  featuresCards[4].color}}
            className="service_comp_mainContainer_btn"
            // onClick={handleStartNow}
            onClick={() => navigate('/intelli-ai-chat')}
          >
            Start Now
          </button>
        }

    </div>
  )
}

export default AiScreenComp
