import React from 'react'
import "../styles/serviceComp.css"

function Error() {
  return (
    <div className='main_container'>
      <div className='main_container_attempt_exam'>
      <h1 >OOPS,Page Not Found !</h1>

      </div>
     
    </div>
  )
}

export default Error
